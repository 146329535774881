import { Dialog, DialogTitle } from "@mui/material";
import AuthenticatedImg from "../../../../layout/AuthenticatedImage";

type ArtikelImageTooltipProps = {
    artikelName?: string;
    imageSrc?: string;
    open: boolean;
    onClose: Function;
};
export default function ArtikelImageTooltip(props: ArtikelImageTooltipProps) {
    if (props.artikelName === undefined) {
        return null;
    }

    let img = null;
    let title = "Kein Bild vorhanden.";
    if (props.imageSrc) {
        title = "Bild für Artikel " + props.artikelName;
        img = <AuthenticatedImg alt={props.artikelName} src={props.imageSrc} inList={false}></AuthenticatedImg>;
    }

    return (
        <Dialog sx={{}} open={props.open} onClose={() => props.onClose()}>
            <DialogTitle>{title}</DialogTitle>
            {img}
        </Dialog>
    );
}
