import { Anforderung } from "../../../../Models/Anforderung";
import { KommissionierungsStatus } from "../../../../Models/Kommissionierung";
import EditNoteIcon from "@mui/icons-material/EditNote";

type FlagAnforderungForLogistikProps = {
    anforderung: Anforderung;
};
/// Use this Component to mark an Anforderung as important for Logistik
export default function FlagAnforderungForLogistik({ anforderung }: FlagAnforderungForLogistikProps) {
    if (anforderung && anforderung.kommissionierungen) {
        let withWarenIO = anforderung.kommissionierungen.filter((k) => k.status === KommissionierungsStatus.WarenIO);

        if (withWarenIO.length > 0) {
            return <EditNoteIcon sx={{ marginLeft: 0, marginTop: -1.5 }} color="primary" fontSize="small" titleAccess="Enthält Kommissionierungen im Zustand WarenIO" />;
        }
    }
    return <></>;
}
