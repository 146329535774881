import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./msalConfig";

export default function useAuthentication() {
    const { accounts, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const username = accounts[0]?.name;

    const rolesString = accounts[0]?.idTokenClaims?.extension_Rollen as string;
    const roles = rolesString ? rolesString.split(",") : [];

    const firmenLako = accounts[0]?.idTokenClaims?.extension_Firmen_LaKo as string;
    const firmenIdsLako = firmenLako ? firmenLako.split(",") : [];

    const firmenAnforderer = (accounts[0]?.idTokenClaims?.extension_Firmen_Anforderer as string)?.split(",");
    const firmaIdAnforderer = firmenAnforderer ? firmenAnforderer[0] : "";

    const login = async () => {
        await instance.loginRedirect(loginRequest);
    };

    const logout = async () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    const hasRole = (...role: string[]) => {
        return roles?.some((r) => role.includes(r));
    };

    return { accounts, instance, isAuthenticated, username, roles, firmaIdAnforderer, firmenIdsLako, hasRole, login, logout };
}
