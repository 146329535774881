import { KommissionierungsPosition } from "./KommissionierungsPosition";

export type Kommissionierung = {
  id: string;
  isuLieferschein: string;
  comment: string;
  positionen: KommissionierungsPosition[];
  createdAt: Date;
  status: KommissionierungsStatus;
};

export enum KommissionierungsStatus {
  Offen = "Offen",
  InKommissionierung = "InKommissionierung",
  Versandbereit = "Versandbereit",
  Versendet = "Versendet",
  WarenAngenommen = "WarenAngenommen",
  WarenIO = "WarenIO",
  Abgeschlossen = "Abgeschlossen",
}
