import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ListIcon from "@mui/icons-material/List";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import { KommissionierungsStatus } from "../../../Models/Kommissionierung";

export const KommissionierungsStatusAsText = (props: { status: KommissionierungsStatus }) => {
  const getIcon = (status: KommissionierungsStatus) => {
    switch (status) {
      case KommissionierungsStatus.Abgeschlossen:
        return <CheckCircleOutlineIcon color="action" />;
      case KommissionierungsStatus.Versendet:
        return <LocalShippingIcon color="action" />;
      case KommissionierungsStatus.InKommissionierung:
        return <ListIcon color="action" />;
      case KommissionierungsStatus.Offen:
        return <ScheduleIcon color="action" />;
      case KommissionierungsStatus.Versandbereit:
        return <WidgetsIcon color="action" />;
      case KommissionierungsStatus.WarenAngenommen:
        return <AssignmentReturnIcon color="action" />;
      case KommissionierungsStatus.WarenIO:
        return <ThumbUpIcon color="action" />;
      default:
        return <ScheduleIcon color="action" />;
    }
  };

  return (
    <>
      {getIcon(props.status)}{" "}
      <span
        style={{
          marginLeft: "10px",
          verticalAlign: "top",
        }}
      >
        {props.status}
      </span>
    </>
  );
};
