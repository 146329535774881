import "./DisplayAnforderung.css";
import { Alert, Button, IconButton, LinearProgress, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useCallback, useEffect, useState } from "react";
import { Anforderung } from "../../../../Models/Anforderung";
import { KommissioniertePosition, useKommissioniereAnforderung } from "./useKommissioniereAnforderung";
import { useUpdateAnforderungBemerkung } from "./useUpdateAnforderungBemerkung";
import PrintIcon from "@mui/icons-material/Print";
import BenutzerDialog from "./BenutzerDialog";
import { Kommissionierung, KommissionierungsStatus } from "../../../../Models/Kommissionierung";
import { KommissionierungsPosition } from "../../../../Models/KommissionierungsPosition";
import { useUpdateKommissionierungsStatus } from "./useUpdateKommissionierungsStatus";
import { KommissionierungsStatusAsText } from "../KommissionierungsStatusAsText";
import useGetAnforderungenById from "./useGetAnforderungenById";
import { AnforderungsPositionenTabelle } from "./AnforderungsPositionenTabelle";
import StornosView from "./../../../components/StornosView";

export type DisplayAnforderungProps = {
    id: string;
    anforderungChangedCallback: Function;
    editable?: boolean;
};
export const DisplayAnforderung = (props: DisplayAnforderungProps) => {
    const { id, anforderungChangedCallback, editable } = props;

    const [anforderung, setAnforderung] = useState<Anforderung>();

    const { data, error: anfError, loaded: anfLoaded, loadAnforderungById } = useGetAnforderungenById();

    const [isuLieferschein, setIsuLieferschein] = useState<string>("");
    const [kommissionierungBemerkung, setKommissionierungBemerkung] = useState<string>("");
    const [bemerkung, setBemerkung] = useState<string>("");

    const { anforderungKommissionieren, error, loaded } = useKommissioniereAnforderung();

    const { updateKommissionierungsStatus, updateError: updateErrorKomStatus } = useUpdateKommissionierungsStatus();

    const [kommissionierteMengen, setKommissionierteMengen] = useState<{
        [positionsId: string]: number;
    }>({});

    const { updateAnforderungBemerkung, updateError: updateBemerkungError } = useUpdateAnforderungBemerkung();

    // TODO - Check This!
    const saveLogistikBemerkung = useCallback(
        async (id: string, bemerkung: string) => {
            await updateAnforderungBemerkung(id, bemerkung);
        },
        [updateAnforderungBemerkung]
    );

    const updateZuKommissionieren = (positionId: string, amount: number) => {
        let newKommissionierteMengen = {
            ...kommissionierteMengen,
        };
        newKommissionierteMengen[positionId] = amount;
        setKommissionierteMengen(newKommissionierteMengen);
    };

    const saveKommissionierungsStatus = async (kommId: string, newStatus: KommissionierungsStatus) => {
        await updateKommissionierungsStatus(kommId, newStatus);
        await loadAnforderungById(id);
        anforderungChangedCallback();
    };

    useEffect(() => {
        loadAnforderungById(id); // reload Anforderung when id changes
    }, [id]);

    useEffect(() => {
        setAnforderung(data); // set Anforderung (state) when loadAnforderungById comes back.
    }, [data]);

    useEffect(() => {
        if (anforderung != null) {
            setBemerkung(anforderung.logistikBemerkung ? anforderung.logistikBemerkung : "");
        }
        setKommissionierteMengen({});
    }, [anforderung]);

    if (!id) {
        return <></>;
    }

    return (
        <>
            {!anfLoaded && <LinearProgress />}
            {!loaded && <LinearProgress />}
            {(error !== "" || updateBemerkungError !== "" || updateErrorKomStatus !== "" || anfError !== "") && (
                <Alert severity="error">
                    <span>{"Ein Fehler ist aufgetreten! " + updateBemerkungError + updateErrorKomStatus}</span>
                </Alert>
            )}
            {anforderung && (
                <>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell width={"15%"} variant="head">
                                    Anforderer
                                </TableCell>
                                <TableCell width={"35%"}>{anforderung.anforderer.name}</TableCell>
                                <TableCell width={"15%"} variant="head">
                                    Besteller
                                </TableCell>
                                <TableCell width={"35%"}>
                                    <BenutzerDialog benutzerMail={anforderung.bestellerEmail} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width={"15%"} variant="head">
                                    Abrufnummer
                                </TableCell>
                                <TableCell width={"35%"}>{anforderung.bestellnummer}</TableCell>
                                <TableCell width={"15%"} variant="head">
                                    Lager
                                </TableCell>
                                <TableCell width={"35%"}>
                                    {anforderung.lager.name} ({anforderung.lager.nummer})
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Bemerkung Anforderer</TableCell>
                                <TableCell sx={{ whiteSpace: "pre-wrap" }}>{anforderung.anfordererBemerkung}</TableCell>
                                <TableCell>Bemerkung Logistik</TableCell>
                                <TableCell>
                                    {editable ? (
                                        <>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={bemerkung}
                                                size="small"
                                                onChange={(el) => setBemerkung(el.currentTarget.value)}
                                                onBlur={(ev) => {
                                                    if (anforderung !== undefined && ev.currentTarget.value !== anforderung.logistikBemerkung) {
                                                        saveLogistikBemerkung(anforderung.id, ev.currentTarget.value);
                                                    }
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>{anforderung.logistikBemerkung}</>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Status Abruf</TableCell>
                                <TableCell>
                                    <>{anforderung.bearbeitungsStatus}</>
                                </TableCell>
                                <TableCell>Bestelleingang</TableCell>
                                <TableCell>{new Date(anforderung.createdAt).toLocaleDateString("de-DE")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <hr />
                    <br />
                    <AnforderungsPositionenTabelle
                        positionen={anforderung.positionen}
                        kommissionierungen={anforderung.kommissionierungen}
                        stornierungen={anforderung.stornierungen}
                        zuKommissionierenCallBack={(positionId, amount) => updateZuKommissionieren(positionId, amount)}
                        editable={editable}
                        stornoCallback={async (posId: string) => {
                            await loadAnforderungById(id);
                            anforderungChangedCallback();
                        }}
                    />
                    <br />
                    {editable && (
                        <Table
                            size="small"
                            sx={{
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                    //border: "1px solid black ! important",
                                },
                            }}
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell variant="head">Kommentar</TableCell>
                                    <TableCell colSpan={2}>
                                        <TextField
                                            fullWidth
                                            onChange={(el) => setKommissionierungBemerkung(el.currentTarget.value)}
                                            value={kommissionierungBemerkung}
                                            label="Kommentar"
                                            placeholder="3 Gitterboxen. Gewicht 1000kg"
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="spaceHolderKommissionieren"></TableCell>
                                    <TableCell className="numbersCell" variant="head">
                                        Lieferschein
                                    </TableCell>
                                    <TableCell className="doubleNumbersCell">
                                        <TextField
                                            fullWidth
                                            onChange={(el) => setIsuLieferschein(el.currentTarget.value)}
                                            value={isuLieferschein}
                                            label="ISU Lieferscheinnummer"
                                            placeholder="AB-0815"
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell className="lagersCell">
                                        <Button
                                            sx={{ margin: "2px" }}
                                            variant="contained"
                                            disabled={
                                                Object.entries(kommissionierteMengen)
                                                    .map((m) => m[1])
                                                    .reduce((a, b) => a + b, 0) === 0
                                            }
                                            onClick={async () => {
                                                let kommPositionen: KommissioniertePosition[] = Object.entries(kommissionierteMengen).map((entry) => {
                                                    return {
                                                        anforderungsPositionId: entry[0],
                                                        kommissionierteAnzahl: entry[1],
                                                    } as KommissioniertePosition;
                                                });
                                                setKommissionierteMengen({});
                                                await anforderungKommissionieren(anforderung.id, isuLieferschein, kommissionierungBemerkung, kommPositionen);
                                                loadAnforderungById(anforderung.id);
                                                setIsuLieferschein("");
                                                setKommissionierungBemerkung("");
                                            }}
                                        >
                                            Kommissionieren
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    )}
                    <Kommissionierungen editable={editable} kommissionierungen={anforderung.kommissionierungen} statusChangedCallback={saveKommissionierungsStatus} />
                    <StornosView stornos={anforderung.stornierungen} />
                </>
            )}
        </>
    );
};

type KommissionierungenProps = {
    kommissionierungen: Kommissionierung[];
    statusChangedCallback: (id: string, newValue: KommissionierungsStatus) => void;
    editable?: boolean;
};
const Kommissionierungen = (props: KommissionierungenProps) => {
    const { kommissionierungen, statusChangedCallback, editable } = props;
    const openPrint = (id: string) => {
        window.open(`/logistik/lieferschein/${id}`, "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <hr />
            <h3>Warenlieferungen</h3>
            {kommissionierungen && kommissionierungen.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ISU Lieferschein</TableCell>
                            <TableCell>Datum</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Kommentar</TableCell>
                            <TableCell>Position: Menge</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {kommissionierungen
                            .sort((k, v) => {
                                return new Date(v.createdAt).getTime() - new Date(k.createdAt).getTime();
                            })
                            .map((val: Kommissionierung, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{val.isuLieferschein}</TableCell>
                                        <TableCell>{formatDate(val.createdAt)}</TableCell>
                                        <TableCell>
                                            {editable ? (
                                                <KommissionierungsStatusPicker
                                                    initialValue={val.status}
                                                    values={Object.values(KommissionierungsStatus).filter(
                                                        (v) => ![KommissionierungsStatus.WarenAngenommen, KommissionierungsStatus.WarenIO].includes(v)
                                                    )}
                                                    onChange={(newVal: KommissionierungsStatus) => {
                                                        statusChangedCallback(val.id, newVal);
                                                    }}
                                                />
                                            ) : (
                                                <KommissionierungsStatusAsText status={val.status} />
                                            )}
                                        </TableCell>
                                        <TableCell>{val.comment}</TableCell>
                                        <TableCell>
                                            {val.positionen.map((pos: KommissionierungsPosition, index: number) => {
                                                return (
                                                    <div key={index}>
                                                        {pos.anforderungsPosition.artikel.artikelnummer}: {pos.anzahl}
                                                    </div>
                                                );
                                            })}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => openPrint(val.id)}>
                                                <PrintIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            ) : (
                <>keine</>
            )}
        </>
    );
};

type KommissionierungsStatusPickerProps = {
    initialValue: KommissionierungsStatus;
    values: KommissionierungsStatus[];
    onChange: (newValue: KommissionierungsStatus) => void;
};
const KommissionierungsStatusPicker = (props: KommissionierungsStatusPickerProps) => {
    const { initialValue, values, onChange } = props;
    const [updatedStatus, setUpdatedStatus] = useState<KommissionierungsStatus>(initialValue);

    useEffect(() => {
        setUpdatedStatus(initialValue);
    }, [initialValue]);

    return (
        <>
            <Select
                size="small"
                sx={{ width: "100%" }}
                value={updatedStatus.toString()}
                renderValue={(v: string) => {
                    return <span>{v}</span>;
                }}
                onChange={(e) => {
                    let newStatus = e.target.value as unknown as KommissionierungsStatus;
                    setUpdatedStatus(newStatus);
                    onChange(newStatus);
                }}
            >
                {values.map((v, i) => {
                    return (
                        <MenuItem value={v} key={i}>
                            {v}
                        </MenuItem>
                    );
                })}
            </Select>
        </>
    );
};

const formatDate = (ds: Date) => {
    return new Date(ds).toLocaleDateString();
};
