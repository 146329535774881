import {
    Alert,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    MenuItem,
    MenuProps,
    Select,
    SelectChangeEvent,
    Snackbar,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Lager } from "../../../../Models/Lager";
import useGetLager from "../../LagerBestand/useGetLager";
import useLagerOfFirma from "./useLagerOfFirma";

export type AddLagerToFirmaDialogProps = {
    firmaId: number | undefined;
    onClose: () => void;
    assignedLager: Lager[];
};

export default function AddLagerToFirmaDialog(props: AddLagerToFirmaDialogProps) {
    const { error, loaded, setFirmenLager: addLagerToFirma } = useLagerOfFirma();

    const { data: allLager } = useGetLager(true);

    const [showSnack, setShowSnack] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof selected>) => {
        const {
            target: { value },
        } = event;
        setSelected(typeof value === "string" ? value.split(",") : value);
    };

    const availableLager = useMemo(() => {
        let availableLager: Lager[] = [];
        allLager.filter((aL) => !props.assignedLager.find((l) => aL.id === l.id)).forEach((l) => availableLager.push(l));
        return availableLager;
    }, [props.assignedLager, allLager]);

    const menuProps: Partial<MenuProps> = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left",
        },
    };

    return (
        <>
            {props.firmaId && (
                <Dialog
                    open={props.firmaId !== undefined}
                    onClose={() => {
                        setSelected([]);
                        props.onClose();
                    }}
                >
                    <DialogTitle>Lager einer Firma zuweisen</DialogTitle>
                    <DialogContent>
                        {!loaded ? (
                            <LinearProgress></LinearProgress>
                        ) : (
                            <>
                                <Snackbar
                                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                    onClose={() => {
                                        setShowSnack(false);
                                        props.onClose();
                                    }}
                                    open={showSnack}
                                    autoHideDuration={1500}
                                >
                                    <Alert
                                        onClose={() => {
                                            setShowSnack(false);
                                        }}
                                        severity="success"
                                        sx={{ width: "100%" }}
                                    >
                                        Lager wurde aktualisiert!
                                    </Alert>
                                </Snackbar>
                                <DialogContentText>
                                    Bitte wähle die Lager aus die du der Firma zuweisen willst
                                    {error !== "" ? (
                                        <>
                                            <br></br>
                                            <span style={{ color: "red" }}>Ein Fehler ist aufgetreten: {error}</span>
                                        </>
                                    ) : null}
                                </DialogContentText>
                                {props.firmaId && (
                                    <>
                                        <br></br>
                                        {availableLager && (
                                            <Select
                                                multiple
                                                value={selected}
                                                onChange={handleChange}
                                                MenuProps={menuProps}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((value) => {
                                                            let lager = allLager.find((l) => l.nummer === value)!;

                                                            return lager && <Chip key={value} label={lager.nummer + " " + lager.name} />;
                                                        })}
                                                    </Box>
                                                )}
                                            >
                                                {availableLager.map((a) => {
                                                    return (
                                                        <MenuItem key={a.id} value={a.nummer}>
                                                            {a.nummer + " " + a.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {error && <span style={{ color: "red" }}>{"Ein Fehler ist aufgetreten! " + error}</span>}
                        <Button
                            onClick={() => {
                                setSelected([]);
                                props.onClose();
                            }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            onClick={async () => {
                                if (props.firmaId) {
                                    const newAssignedLager = props.assignedLager.map((l) => l.nummer);
                                    selected.forEach((s) => newAssignedLager.push(s));
                                    await addLagerToFirma(props.firmaId, newAssignedLager);
                                    setShowSnack(true);
                                    setSelected([]);
                                }
                            }}
                        >
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
