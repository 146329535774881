import { Toolbar } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid-pro";

export default function CustomGridToolbar() {
  return (
    <Toolbar>
      <GridToolbarQuickFilter />
    </Toolbar>
  );
}
