/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
const b2cPolicies = {
    names: {
        combinedSignIn: "B2C_1_SignIn",
    },
    authorities: {
        combinedSignIn: {
            authority: process.env.REACT_APP_MSAL_AUTHORITY,
        },
        internalSignUp: {
            authority: process.env.REACT_APP_MSAL_AUTHORITY_SIGNUP,
        },
    },
    authorityDomain: process.env.REACT_APP_MSAL_AUTHORITY_DOMAIN,
};

export default b2cPolicies;
