import { Toolbar } from "@mui/material";
import { GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";

export default function CustomGridToolbarWithExport() {
    return (
        <Toolbar>
            <GridToolbarQuickFilter />
            <GridToolbarExport
                csvOptions={{
                    fileName: "angelo-export",
                    delimiter: ";",
                    utf8WithBom: true,
                }}
                printOptions={{
                    disableToolbarButton: true,
                }}
            />
        </Toolbar>
    );
}
