import "./index.css";
import Home, { LoginScreen } from "./Home";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ArtikelPage from "./applications/bestellung/Artikel/ArtikelPage";
import Warenkorb from "./applications/bestellung/Warenkorb/Warenkorb";

import { BESTELLUNG_ANFORDERUNGEN_PATH, BESTELLUNG_HISTORIE_PATH, LOGISTIK_ANFORDERUNGEN_PATH, LOGISTIK_HISTORIE_PATH } from "./layout/Navigation";
import AllLagerBestand from "./applications/logistik/LagerBestand/AllLagerBestand";

import { ThemeProvider } from "@mui/material";
import ArtikelMgmt from "./applications/logistik/artikel/administration/ArtikelMgmt";
import FirmenMgmt from "./applications/logistik/FirmenVerwaltung/FirmenMgmt";
import MeineFirma from "./applications/bestellung/MeineFirma/MeineFirma";
import Anforderungshistorie from "./applications/bestellung/Anforderungshistorie/Anforderungshistorie";
import OffeneAnforderungen from "./applications/bestellung/OffeneAnforderungen/OffeneAnforderungen";
import Historie from "./applications/logistik/anforderungen/historie/Historie";
import AnforderungInBearbeitungList from "./applications/logistik/anforderungen/inbearbeitung/AnforderungInBearbeitungList";
import { AppContextProvider } from "./context/AppContext";
import NetzeBW_Theme from "./layout/NetzeBW_Theme";
import LagerBestand from "./applications/bestellung/LagerInformation/LagerBestand";
import LieferscheinDrucken from "./applications/logistik/Kommissionierung/LieferscheinDrucken";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import msalInstance2 from "./authentication/msalInstance";
import SecuredPage from "./authentication/SecuredPage";
import SecuredPageLogistik from "./authentication/SecuredPageLogistik";
import UserManagement from "./applications/administration/UserManagement";
import SecuredPageAdministrator from "./authentication/SecuredPageAdministrator";

export default function App() {
    return (
        <ThemeProvider theme={NetzeBW_Theme}>
            <MsalProvider instance={msalInstance2}>
                <UnauthenticatedTemplate>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/*" element={<LoginScreen />} />
                        </Routes>
                    </BrowserRouter>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <AppContextProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route
                                    path="/bestellung"
                                    element={
                                        <SecuredPage>
                                            <Navigate to="/bestellung/artikel" />
                                        </SecuredPage>
                                    }
                                />
                                <Route
                                    path="/bestellung/artikel"
                                    element={
                                        <SecuredPage>
                                            <ArtikelPage />
                                        </SecuredPage>
                                    }
                                ></Route>
                                <Route
                                    path="/bestellung/warenkorb"
                                    element={
                                        <SecuredPage>
                                            <Warenkorb />
                                        </SecuredPage>
                                    }
                                />
                                <Route
                                    path={BESTELLUNG_ANFORDERUNGEN_PATH + ":bestellnummer"}
                                    element={
                                        <SecuredPage>
                                            <OffeneAnforderungen />
                                        </SecuredPage>
                                    }
                                />
                                <Route
                                    path={BESTELLUNG_ANFORDERUNGEN_PATH}
                                    element={
                                        <SecuredPage>
                                            <OffeneAnforderungen />
                                        </SecuredPage>
                                    }
                                />
                                <Route
                                    path={BESTELLUNG_HISTORIE_PATH}
                                    element={
                                        <SecuredPage>
                                            <Anforderungshistorie />
                                        </SecuredPage>
                                    }
                                />
                                <Route
                                    path={BESTELLUNG_HISTORIE_PATH + ":bestellnummer"}
                                    element={
                                        <SecuredPage>
                                            <Anforderungshistorie />
                                        </SecuredPage>
                                    }
                                />
                                <Route
                                    path="/bestellung/meineFirma"
                                    element={
                                        <SecuredPage>
                                            <MeineFirma />
                                        </SecuredPage>
                                    }
                                />
                                <Route
                                    path="/bestellung/lagerbestand"
                                    element={
                                        <SecuredPage>
                                            <LagerBestand />
                                        </SecuredPage>
                                    }
                                />
                                <Route
                                    path="/logistik"
                                    element={
                                        <SecuredPageLogistik>
                                            <Navigate to={LOGISTIK_ANFORDERUNGEN_PATH} />
                                        </SecuredPageLogistik>
                                    }
                                />
                                <Route
                                    path="logistik/artikelverwaltung"
                                    element={
                                        <SecuredPageLogistik>
                                            <ArtikelMgmt />
                                        </SecuredPageLogistik>
                                    }
                                ></Route>
                                <Route
                                    path="logistik/lager"
                                    element={
                                        <SecuredPageLogistik>
                                            <AllLagerBestand />
                                        </SecuredPageLogistik>
                                    }
                                />
                                <Route
                                    path={LOGISTIK_HISTORIE_PATH + ":bestellnummer"}
                                    element={
                                        <SecuredPageLogistik>
                                            <Historie />
                                        </SecuredPageLogistik>
                                    }
                                />
                                <Route
                                    path={LOGISTIK_HISTORIE_PATH}
                                    element={
                                        <SecuredPageLogistik>
                                            <Historie />
                                        </SecuredPageLogistik>
                                    }
                                />
                                <Route
                                    path={LOGISTIK_ANFORDERUNGEN_PATH}
                                    element={
                                        <SecuredPageLogistik>
                                            <AnforderungInBearbeitungList />
                                        </SecuredPageLogistik>
                                    }
                                />
                                <Route
                                    path={LOGISTIK_ANFORDERUNGEN_PATH + ":bestellnummer"}
                                    element={
                                        <SecuredPageLogistik>
                                            <AnforderungInBearbeitungList />
                                        </SecuredPageLogistik>
                                    }
                                />
                                <Route
                                    path="logistik/firmen"
                                    element={
                                        <SecuredPageLogistik>
                                            <FirmenMgmt />
                                        </SecuredPageLogistik>
                                    }
                                />
                                <Route
                                    path="logistik/lieferschein/:id"
                                    element={
                                        <SecuredPageLogistik hideHeaders={true}>
                                            <LieferscheinDrucken />
                                        </SecuredPageLogistik>
                                    }
                                />
                                <Route
                                    path="administration/users"
                                    element={
                                        <SecuredPageAdministrator>
                                            <UserManagement />
                                        </SecuredPageAdministrator>
                                    }
                                />
                            </Routes>
                        </BrowserRouter>
                    </AppContextProvider>
                </AuthenticatedTemplate>
            </MsalProvider>
        </ThemeProvider>
    );
}
