import { useState } from "react";
import axios from "../../../../ApiClient";

const DELETE_USER_URL = "/angelo/logistik/mitarbeiter/{email}";

export default function useDeleteMitarbeiter() {
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [result, setResult] = useState<string>("");

    const deleteMitarbeiter = async (firmaId: number, email: string) => {
        setDeleting(true);
        setError("");
        try {
            let response = await axios.delete(DELETE_USER_URL.replace("{id}", firmaId.toString()).replace("{email}", email));
            setResult(response.data);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setDeleting(false);
        }
    };

    return { result, deleting, error, deleteMitarbeiter };
}
