import { Grid } from "@mui/material";
import "./Header.css";
import Stamp from "./Stamp";

function Header() {
  return (
    <>
      <Grid container spacing={0}>
        <Grid item paddingLeft={5} paddingTop={"20px"}>
          <a href="/"><img alt="netze-bw logo" src="/netzebw.png" width="200"></img></a>
        </Grid>
        <Grid item paddingLeft={"10px"}>
          <h1>
              <a href="/"> - AnGeLo</a>
          </h1>
        </Grid>
      </Grid>
      <Stamp environment={process.env.REACT_APP_STAGE} />
    </>
  );
}

export default Header;
