import { Alert, LinearProgress } from "@mui/material";
import { LagerInformation } from "../../../Models/LagerInformation";
import ShowLagerInformation from "./ShowLagerInformation";
import useGetLagerInformation from "./useGetLagerInformation";
import useAuthentication from "./../../../authentication/useAuthentication";

export default function LagerBestand() {
    const { firmaIdAnforderer } = useAuthentication();
    const { data, error, loaded } = useGetLagerInformation(firmaIdAnforderer);

    return (
        <div className="contentDiv">
            {error !== "" && (
                <Alert severity="error">
                    <span>{"Ein Fehler ist aufgetreten! " + error}</span>
                </Alert>
            )}
            {!loaded && <LinearProgress />}
            {loaded && (
                <>
                    {data.map((l: LagerInformation) => {
                        return <ShowLagerInformation key={l.id} lager={l} />;
                    })}
                </>
            )}
        </div>
    );
}
