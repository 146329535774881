import { useContext, useEffect, useState } from "react";
import { Artikel } from "../../../Models/Artikel";
import { CartItem } from "./CartItem";
import axios from "../../../ApiClient";
import { Lager } from "../../../Models/Lager";
import { AppContext } from "../../../context/AppContext";
import useAuthentication from "../../../authentication/useAuthentication";
import { UserRoles } from "./../../../Models/User";

const CART_URL = "/angelo/warenkorb";
const ORDER_URL = "/angelo/warenkorb/bestellposition/";
const FULLFILLMENT_URL = "/angelo/warenkorb/wandlung/";
const LAGER_URL = "/angelo/warenkorb/lager";
const BEMERKUNG_URL = "/angelo/warenkorb/bemerkung";

export const useWarenkorb = () => {
    const { isAuthenticated, hasRole } = useAuthentication();
    const [entries, setEntries] = useState<CartItem[]>([]);
    const [anfordererBemerkung, setAnfordererBemerkung] = useState("");
    const { cartLager, setCartLager } = useContext(AppContext);
    const [reload, setReload] = useState(false);
    const [error, setError] = useState("");
    const [fullFillError, setFullFillError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [updatingBemerkung, setUpdatingBemerkung] = useState(false);

    useEffect(() => {
        if (isAuthenticated && hasRole(UserRoles.ANFORDERER)) {
            const loadWarenkorb = async () => {
                setLoaded(false);
                try {
                    const response = await axios.get(CART_URL);
                    const bestellpositionen = await response.data.bestellpositionen;
                    setEntries(bestellpositionen);
                    setCartLager(response.data.lager);
                    setAnfordererBemerkung(response.data.anfordererBemerkung);
                } catch (error: any) {
                    setError(error.message);
                    setCartLager(undefined);
                    setEntries([]);
                    setAnfordererBemerkung("");
                } finally {
                    setLoaded(true);
                }
            };
            loadWarenkorb();
        }
    }, [reload, isAuthenticated]);

    const deleteItem = async (id: any) => {
        await axios
            .delete(ORDER_URL + id)
            .then((res) => {
                console.log("Element AKtualisiert: ", res);
            })
            .catch((err) => {
                console.log("Fehler beim löschen eines Items", err);
            });

        setReload(!reload);
    };

    const updateItem = async (item: CartItem) => {
        await axios
            .put(ORDER_URL + item.id, { newAnzahl: item.anzahl })
            .then((res) => {
                console.log("Element AKtualisiert: ", res);
            })
            .catch((err) => {
                console.log("Fehler beim aktualisieren eines Items", err);
            });
        setReload(!reload);
    };

    const setLager = async (lager: Lager) => {
        if (lager !== cartLager) {
            // only reset warenkorb, if lager changed
            await axios
                .put(LAGER_URL, { lagerId: lager.id })
                .then((res) => {
                    setCartLager(res.data.lager);
                    setEntries([]);
                })
                .catch((err) => {
                    console.log("Fehler beim aktualisieren des Lagers", err);
                });
            setReload(!reload);
        }
    };

    const setBemerkung = async (bemerkung: string) => {
        setUpdatingBemerkung(true);
        await axios
            .put(BEMERKUNG_URL, { bemerkung: bemerkung })
            .catch((err) => {
                console.log("Fehler beim aktualisieren der Bemerkung", err);
            })
            .finally(() => {
                setUpdatingBemerkung(false);
            });
        //setReload(!reload);
    };

    const addItem = async (item: Artikel, amount: number) => {
        await axios
            .post(ORDER_URL, {
                artikelNummer: item.artikelnummer,
                anzahl: amount,
            })
            .then((res) => {
                console.log("Neues Element im Warenkorb gespeichert: ", res);
            })
            .catch((er) => {
                console.log("Fehler beim Speichern: ", er);
            });
        setReload(!reload);
    };

    const fullfill = async () => {
        setLoaded(false);
        setFullFillError("");
        if (cartLager === undefined) {
            setLoaded(true);
            setFullFillError("Ein Lager muss ausgewählt sein um einen Abruf durchzuführen!");
            return;
        }

        let result = true;

        await axios
            .post(FULLFILLMENT_URL, { lagerId: cartLager!.id })
            .then((res) => {
                console.log("Warenkorb wurde zu Anforderung gewandelt", res);
            })
            .catch((er) => {
                let errorMessage = er.response?.data?.message;
                setFullFillError(errorMessage ? errorMessage : er);
                console.log("Fehler beim wandeln des Wahrenkorbs: ", er);
                result = false;
            })
            .finally(() => {
                setLoaded(true);
            });
        setReload((reload) => !reload);
        return result;
    };

    return {
        entries,
        loaded,
        updatingBemerkung,
        error,
        addItem,
        deleteItem,
        updateItem,
        setLager,
        anfordererBemerkung,
        setBemerkung,
        lager: cartLager,
        fullfill,
        fullFillError,
    };
};
