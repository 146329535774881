import { createTheme } from "@mui/material/styles";
import { deDE } from "@mui/x-data-grid-pro/locales";

export const NetzeBW_Theme = createTheme(
    {
        palette: {
            primary: {
                light: "#FF9900",
                main: "#01058E",
                dark: "#01058E",
            },
            secondary: {
                main: "#FF9900",
                contrastText: "#fff",
                dark: "#01058E",
            },
            text: {
                primary: "#000",
                secondary: "#01058E",
            },
        },
        typography: {
            h1: {
                fontSize: 24,
                fontWeight: "bold",
            },
            h3: {
                fontSize: "1.1rem",
                fontWeight: "700",
                color: "#01058E",
                margin: "12px 0px 9px 10px",
            },
            fontSize: 14,
        },
    },
    deDE
);
export default NetzeBW_Theme;
