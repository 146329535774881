import axios from "../../../ApiClient";
import { useCallback, useState } from "react";
import { KommissionierungsStatus } from "../../../Models/Kommissionierung";

const KOMMISSIONIERUNGS_URL = "angelo/firmen/{firmaId}/kommissionierungen/{kommissionierungsid}/status";

export const useUpdateKommissionierungsStatusOfFirma = () => {
  const [updateError, setUpdateError] = useState("");
  const [updateLoaded, setUpdateLoaded] = useState(true);

  const updateKommissionierungsStatus = useCallback(
    async (firmaId: string, kommissionierungsId: string, newStatus: KommissionierungsStatus) => {
      setUpdateLoaded(false);
      try {
        await axios.put(
          KOMMISSIONIERUNGS_URL.replace("{firmaId}", firmaId).replace("{kommissionierungsid}", kommissionierungsId),
          { newStatus: newStatus }
        );
        return true;
      } catch (deactivateError: any) {
        console.log("Error patching KommissionierungsStatus of Firma", deactivateError);
        setUpdateError(deactivateError.message);
        return false;
      } finally {
        setUpdateLoaded(true);
      }
    },
    []
  );

  return { updateKommissionierungsStatus, updateError, updateLoaded };
};
