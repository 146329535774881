import { Button, Dialog, DialogTitle, LinearProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useGetBenutzerDetails from "./useGetBenutzerDetails";

export type BenutzerDialogProps = {
  benutzerMail: string;
};
export default function BenutzerDialog(props: BenutzerDialogProps) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        color="info"
        sx={{ marginBottom: "10px" }}
        variant="outlined"
        onClick={(ev) => {
          setVisible(true);
        }}
      >
        {props.benutzerMail}
      </Button>
      <DialogOverlay onClose={() => setVisible(false)} visible={visible} benutzerMail={props.benutzerMail} />
    </>
  );
}
export type DialogOverlayProps = {
  benutzerMail: string;
  visible: boolean;
  onClose: () => void;
};
export const DialogOverlay = (props: DialogOverlayProps) => {
  const { data, loaded, error, toggleReload } = useGetBenutzerDetails(props.benutzerMail);

  useEffect(() => {
    toggleReload();
  }, [props.benutzerMail]);

  return (
    <Dialog open={props.benutzerMail !== null && props.visible} onClose={() => props.onClose()}>
      {error !== undefined && error !== "" && <span>Ein Fehler ist aufgetreten!</span>}
      <DialogTitle>Benutzer Details</DialogTitle>
      <div style={{ minHeight: "220px" }}>
        {!loaded ? (
          <LinearProgress />
        ) : (
          <>
            <TextField
              sx={{ marginLeft: "30px", marginRight: "30px" }}
              margin="normal"
              size="small"
              disabled
              label="Email"
              defaultValue={props.benutzerMail}
            />
            <br></br>
            <TextField
              sx={{ marginLeft: "30px", marginRight: "30px" }}
              margin="normal"
              size="small"
              disabled
              label="Vorname"
              defaultValue={data?.firstName}
            />
            <br></br>
            <TextField
              sx={{ marginLeft: "30px", marginRight: "30px" }}
              margin="normal"
              size="small"
              disabled
              label="Nachname"
              defaultValue={data?.lastName}
            />
          </>
        )}
      </div>
    </Dialog>
  );
};
