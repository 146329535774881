import { Button } from "@mui/material";
import Header from "../layout/Header";
import Navigation from "../layout/Navigation";
import useAuthentication from "./useAuthentication";
import { UserRoles } from "./../Models/User";

type SecuredPageProps = {
    children: JSX.Element;
};

function SecuredPage(props: Readonly<SecuredPageProps>) {
    const { children } = props;

    const { isAuthenticated, hasRole, logout } = useAuthentication();

    if (isAuthenticated && hasRole(UserRoles.ANFORDERER)) {
        return (
            <>
                <Header />
                <Navigation />
                {children}
            </>
        );
    } else {
        return (
            <>
                <Header />
                <Navigation />
                <div className="contentDiv">
                    Die von dir aufgerufene Seite ist nur für Besteller (Anforderer) zugänglich!
                    <br /> Du bist nicht authorisiert diese Seite zu betreten!
                    <p>Bitte stelle sicher, dass du über die benötigte Rolle verfügst.</p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            logout();
                        }}
                    >
                        Neu anmelden für Materialbestellung
                    </Button>
                </div>
            </>
        );
    }
}

export default SecuredPage;
