import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Lieferschein, LieferscheinPosition } from "../../../Models/Lieferschein";
import PrintIcon from "@mui/icons-material/Print";

export default function LieferscheinDruckenView(props: { lieferschein: Lieferschein }) {
  const lieferschein = props.lieferschein;

  const formatDate = (datum: Date) => {
    return new Date(datum).toLocaleDateString();
  };

  return (
    <>
      <Grid container>
        <Grid item xs={11}>
          <h3>Lieferschein</h3>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => window.print()}>
            <PrintIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Table>
        <TableBody>
          <TableRow>
            <TableLine title="Anforderer" value={lieferschein.anforderer} />
            <TableLine title="Besteller" value={lieferschein.besteller} />
          </TableRow>
          <TableRow>
            <TableLine title="Lager" value={lieferschein.lager} />
            <TableLine title="Abrufnummer" value={lieferschein.bestellnummer} />
          </TableRow>
          <TableRow>
            <TableLine title="Lieferscheinnummer" value={lieferschein.lieferscheinNummer} />
            <TableLine title="Referenz-Lieferschein" value={lieferschein.isuLieferschein} />
          </TableRow>
          <TableRow>
            <TableLine title="Kommentar" value={lieferschein.kommentar} />
            <TableLine title="Datum" value={formatDate(lieferschein.datum)} />
          </TableRow>
        </TableBody>
      </Table>
      <LieferscheinPositionen positionen={lieferschein.positionen} />
    </>
  );
}

const TableLine = (props: { title: string; value: string }) => {
  return (
    <>
      <TableCell variant="head">{props.title}:</TableCell>
      <TableCell>{props.value}</TableCell>
    </>
  );
};

const LieferscheinPositionen = (props: { positionen: LieferscheinPosition[] }) => {
  let lines = props.positionen.map((p: LieferscheinPosition, index: number) => {
    return <LieferscheinPositionLine key={index} position={p} />;
  });

  return (
    <>
      <h3>Positionen</h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Artikelnummer</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Liefermenge</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{lines}</TableBody>
      </Table>
    </>
  );
};

const LieferscheinPositionLine = (props: { position: LieferscheinPosition }) => {
  return (
    <>
      <TableRow>
        <TableCell>{props.position.artikelnummer}</TableCell>
        <TableCell>{props.position.name}</TableCell>
        <TableCell>{props.position.anzahl}</TableCell>
      </TableRow>
    </>
  );
};
