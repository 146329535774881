import { useState } from "react";
import axios from "../../../ApiClient";
import { Anforderung } from "../../../Models/Anforderung";

//Use "http://localhost:3000/data/Firmen.json" to load debug sample data
const SHAREPOINT_INBEARBEITUNG_URL = "/angelo/firmen/{firmaId}/anforderungen/{id}";

const useGetAnforderungenByFirmaById = () => {
    const [data, setData] = useState<Anforderung>();
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);

    const loadAnforderungById = async (firmaId: string, id: string) => {
        setLoaded(false);
        setError("");
        try {
            const response = await axios.get<Anforderung>(SHAREPOINT_INBEARBEITUNG_URL.replaceAll("{firmaId}", firmaId).replaceAll("{id}", id));
            const json = response.data;
            setData(json);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoaded(true);
        }
    };

    return { data, error, loaded, loadAnforderungById };
};

export default useGetAnforderungenByFirmaById;
