import { useEffect, useState } from "react";
import axios from "../../../ApiClient";
import { LagerInformation } from "../../../Models/LagerInformation";

const VIEW_LAGER_URL = "/angelo/firmen/{firmaId}/lagerinformation";

const useGetLagerInformation = (firmaId: string) => {
  const [data, setData] = useState<LagerInformation[]>([]);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadLager = async () => {
      try {
        if (firmaId === undefined) {
          return;
        }
        const response = await axios.get<LagerInformation[]>(VIEW_LAGER_URL.replace("{firmaId}", firmaId));
        const json = response.data;
        setData(json);
      } catch (error: any) {
        console.log("Fehler beim Laden des Lagers", error);
        setError(error.message);
      } finally {
        setLoaded(true);
      }
    };
    loadLager();
  }, []);
  return { data, error, loaded };
};

export default useGetLagerInformation;
