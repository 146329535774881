import { LinearProgress } from "@mui/material";
import ShowLagerInformation from "../../bestellung/LagerInformation/ShowLagerInformation";

import useGet from "../../../applications/hooks/useGet";
import { LagerInformation } from "../../../Models/LagerInformation";
import ErrorDisplay from "../../../applications/components/ErrorDisplay";
import { useEffect, useState } from "react";

function AllLagerBestand() {
    const { data, error, isLoading } = useGet<LagerInformation>("/angelo/logistik/lagerinformation");
    const [sortedLager, setSortedLager] = useState<LagerInformation[]>([]);

    useEffect(() => {
        if (data !== undefined) {
            const sortedArray = data.toSorted((a: LagerInformation, b: LagerInformation) => {
                if (a.nummer === "lager-qs" || a.nummer === "lager-zentral") {
                    return 100;
                } else if (b.nummer === "lager-qs" || b.nummer === "lager-zentral") {
                    return -100;
                } else {
                    return a.nummer.localeCompare(b.nummer);
                }
            });
            setSortedLager(sortedArray);
        }
    }, [data]); // we need to sort the lager array!

    return (
        <div className="contentDiv">
            {error && <ErrorDisplay title="Fehler beim Laden der Lager" error={error} />}
            {isLoading && <LinearProgress></LinearProgress>}
            {sortedLager && (
                <>
                    {sortedLager.map((l: LagerInformation) => {
                        return <ShowLagerInformation key={l.id} lager={l} />;
                    })}
                </>
            )}
        </div>
    );
}

export default AllLagerBestand;
