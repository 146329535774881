import { useEffect, useState } from "react";
import { Artikel } from "../../../Models/Artikel";
import axios from "../../../ApiClient";

const ARTIKEL_URL = "/angelo/logistik/artikel";

export const useGetAllArtikel = () => {
    const [data, setData] = useState<Artikel[]>([]);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(true);

    const [refresh, setRefresh] = useState(false);

    const toggleRefresh = () => {
        setRefresh(!refresh);
    };

    useEffect(() => {
        const loadArtikel = async () => {
            setLoaded(false);
            try {
                const response = await axios.get<Artikel[]>(ARTIKEL_URL);
                const json = response.data;
                setData(json);
            } catch (error: any) {
                console.log("Error on fetching articles:", error);
                setError(error.message);
            } finally {
                setLoaded(true);
            }
        };
        loadArtikel();
    }, [refresh]);
    return { data, error, loaded, toggleRefresh };
};
