import axios from "axios";
import { config } from "./config";
import { loginRequest } from "./authentication/msalConfig";
import msalInstance2 from "./authentication/msalInstance";

console.log("Configuring axios with base url: " + config.BASE_URL);

const axiosClient = axios.create({
    baseURL: config.BASE_URL,
});

axiosClient.interceptors.request.use(async (config) => {
    let account = msalInstance2.getAllAccounts()[0];
    if (!account) {
        throw Error("No active account! Verify a user has been signed in.");
    }

    /**
     * MSAL will automatically refresh your access token after
     * expiration when calling acquireTokenSilent.
     * https://stackoverflow.com/a/44527434
     */
    const response = await msalInstance2.acquireTokenSilent({
        ...loginRequest,
        account,
    });

    const bearer = `Bearer ${response.accessToken}`;
    if (config.headers) {
        config.headers["Authorization"] = bearer;
    } else throw Error("Header not present on intercepted object");
    return config;
});

export default axiosClient;
