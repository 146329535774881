import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { LicenseInfo } from "@mui/x-license";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

LicenseInfo.setLicenseKey("54b6d21a483f8a80ad6367bbcf87ad83Tz04NjAwMCxFPTE3NDE0NTEwMTkwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=");

// ReactKeycloakProvider must be outside of react strict mode, as that triggers duplicate loading of components which causes authentication errors
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
