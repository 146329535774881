export type User = {
    id: string;
    email: string;
    displayName: string;
    firstName: string;
    lastName: string;
    isActive: boolean;
    firmaId: string;
    roles: string[];
    firmenLako: string[];
    firmenAnforderer: string[];
    type: string;
};
export default User;

class UserRolesStrings {
    ADMINISTRATOR = "ADMINISTRATOR";
    ANFORDERER = "ANFORDERER";
    ANNAHMERUECKLOGISTIK = "ANNAHMERUECKLOGISTIK";
    KOMMISIONIERER = "KOMMISIONIERER";
    KOORDINATOR = "KOORDINATOR";
    LAGERKOORDINATOR = "LAGERKOORDINATOR";
    RUECKLOGISTIKER = "RUECKLOGISTIKER";

    ALL_ROLES = [this.ADMINISTRATOR, this.ANFORDERER, this.ANNAHMERUECKLOGISTIK, this.KOMMISIONIERER, this.KOORDINATOR, this.LAGERKOORDINATOR, this.RUECKLOGISTIKER];
}
export const UserRoles = new UserRolesStrings();

class UserTypeString {
    INTERN = "INTERN";
    EXTERN = "EXTERN";
}
export const UserTypes = new UserTypeString();
