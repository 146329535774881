import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import { BearbeitungsStatus } from "../../../Models/Anforderung";

export const BearbeitungsStatusAsText = (props: { status: BearbeitungsStatus }) => {
    switch (props.status) {
        case BearbeitungsStatus.Abgeschlossen:
            return <CheckCircleOutlineIcon color="action" titleAccess="Abgeschlossen" />;
        case BearbeitungsStatus.Versendet:
            return <LocalShippingIcon color="action" titleAccess="Versendet" />;
        case BearbeitungsStatus.InKommissionierung:
            return <InventoryIcon color="action" titleAccess="In Kommissionierung" />;
        case BearbeitungsStatus.Offen:
            return <ScheduleIcon color="action" titleAccess="Offen" />;
        case BearbeitungsStatus.Teillieferung:
            return <LocalShippingOutlinedIcon color="action" titleAccess="Teillieferung" />;

        default:
            return <ScheduleIcon color="error" titleAccess={`Unbekannter Status: ${props.status}`} />;
    }
};
