import User, { UserRoles } from "../../Models/User";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid, LinearProgress, MenuItem, Select } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import usePutById from "../hooks/usePutById";
import ErrorDisplay from "../components/ErrorDisplay";
import InfoDisplay from "../components/InfoDisplay";
import { Firma } from "@/Models/Firma";

type SelectRolesProps = {
    user: User;
    firmen: Firma[];
    onChanged: () => void;
    onClosed: () => void;
};
export default function SelectRoles(props: Readonly<SelectRolesProps>) {
    const { user, firmen, onChanged, onClosed } = props;
    const [showForm, setShowForm] = useState(false);
    const [theUser, setTheUser] = useState<User | null>(user);

    const [newRoles, setNewRoles] = useState<string[]>(user.roles);
    const [newFirmenLaKo, setNewFirmenLaKo] = useState<string[]>(user.firmenLako);
    const [newFirmenAnforderer, setNewFirmenAnforderer] = useState<string[]>(user.firmenAnforderer);
    const [closeOnSave, setCloseOnSave] = useState(false);

    const { data, error, isLoading, putObject } = usePutById<User>("angelo/admin/users/{id}");

    const isLaKo = newRoles.includes(UserRoles.LAGERKOORDINATOR);
    const isLaKoValid = (isLaKo && newFirmenLaKo.length > 0) || !isLaKo; // isValid if LaKo and at least one Firma or not LaKo
    const isAnforderer = newRoles.includes(UserRoles.ANFORDERER);
    const isAnfordererValid = (isAnforderer && newFirmenAnforderer.length > 0) || !isAnforderer; // isValid if Anforderer and one Firma or not Anforderer

    const close = () => {
        if (data) {
            onChanged();
        }
        onClosed();
    };

    const save = () => {
        let obj = {
            id: theUser?.id,
            roles: newRoles,
            firmenLako: newFirmenLaKo,
            firmenAnforderer: newFirmenAnforderer,
        } as User;
        putObject(obj);
    };

    const saveAndClose = () => {
        setCloseOnSave(true);
        save();
    };

    useEffect(() => {
        if (closeOnSave && data) {
            close();
        }
    }, [data]);

    useEffect(() => {
        if (user) {
            setTheUser(user);
        }
    }, [user]);

    useEffect(() => {
        if (theUser) {
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    }, [theUser]);

    useEffect(() => {
        if (!isLaKo) {
            setNewFirmenLaKo([]);
        }
    }, [isLaKo]);

    useEffect(() => {
        if (!isAnforderer) {
            setNewFirmenAnforderer([]);
        }
    }, [isAnforderer]);

    return (
        theUser && (
            <Dialog open={showForm} onClose={() => close()} maxWidth="xl" fullWidth={true}>
                <DialogTitle>Benutzer {theUser.displayName}</DialogTitle>
                <DialogContent>
                    {isLoading && <LinearProgress />}
                    {error && <ErrorDisplay title="Fehler beim Speichern des Benutzers" error={error} />}
                    {data && <InfoDisplay title="Benutzer gespeichert" message="Benutzer erfolgreich gespeichert." />}
                    <Grid container>
                        <Grid item xs={3}>
                            Rollen
                        </Grid>
                        <Grid item xs={9}>
                            <Select value={newRoles} multiline={true} size="small" fullWidth multiple onChange={(evt) => setNewRoles(evt.target.value as string[])}>
                                {UserRoles.ALL_ROLES.map((role) => (
                                    <MenuItem key={role} value={role}>
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            Firmen (Lagerkoordinator)
                        </Grid>
                        <Grid item xs={9}>
                            {firmen && (
                                <Select
                                    disabled={!isLaKo}
                                    value={newFirmenLaKo}
                                    multiline={true}
                                    size="small"
                                    fullWidth
                                    multiple
                                    onChange={(evt) => {
                                        setNewFirmenLaKo(evt.target.value as string[]);
                                    }}
                                >
                                    {firmen.map((firma) => (
                                        <MenuItem key={firma.id} value={firma.id.toString()}>
                                            {firma.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                            {!isLaKoValid && <FormLabel error>Ein Lagerkoordinator benötigt mindestens eine Firma!</FormLabel>}
                        </Grid>
                        <Grid item xs={3}>
                            Firmen (Anforderer)
                        </Grid>
                        <Grid item xs={9}>
                            {firmen && (
                                <Select
                                    disabled={!isAnforderer}
                                    value={newFirmenAnforderer}
                                    multiline={false}
                                    size="small"
                                    fullWidth
                                    onChange={(evt) => {
                                        setNewFirmenAnforderer([evt.target.value] as string[]);
                                    }}
                                >
                                    {firmen.map((firma) => (
                                        <MenuItem key={firma.id} value={firma.id.toString()}>
                                            {firma.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                            {!isAnfordererValid && <FormLabel error>Ein Anforderer benötigt eine Firma!</FormLabel>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading || !(isLaKoValid && isAnfordererValid)}
                        onClick={() => save()}
                        variant="outlined"
                        size="small"
                        startIcon={<SaveIcon fontSize="small" />}
                    >
                        Speichern
                    </Button>
                    <Button
                        disabled={isLoading || !(isLaKoValid && isAnfordererValid)}
                        onClick={() => saveAndClose()}
                        variant="outlined"
                        size="small"
                        startIcon={<SaveIcon fontSize="small" />}
                    >
                        Speichern & Schließen
                    </Button>
                    <Button disabled={isLoading} onClick={() => close()} variant="outlined" size="small" startIcon={<CloseIcon fontSize="small" />}>
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        )
    );
}
