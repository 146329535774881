import apiConfig from "./apiConfig";
import b2cPolicies from "./policies";
import * as msal from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: b2cPolicies.authorities.combinedSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
} as Configuration;

const loginRequest = {
    scopes: ["openid", ...apiConfig.b2cScopes],
    authority: b2cPolicies.authorities.combinedSignIn.authority,
    redirectUri: window.location.origin + "/",
} as msal.RedirectRequest;

const signUpRequest = {
    scopes: ["openid", ...apiConfig.b2cScopes],
    authority: b2cPolicies.authorities.internalSignUp.authority,
    redirectUri: window.location.origin + "/",
};

const tokenRequest = {
    scopes: [...apiConfig.b2cScopes],
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
    redirectUri: window.location.origin,
};

export { loginRequest, signUpRequest, tokenRequest, msalConfig };
