import { Alert, AlertTitle, Box, Button, LinearProgress } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import useFirmen from "./useFirmen";
import CustomGridToolbar from "../../../layout/CustomGridToolbar";
import { Firma } from "../../../Models/Firma";
import { useEffect, useState } from "react";
import CreateFirmaDialog from "./CreateFirmaDialog";
import MitarbeiterListe from "./Mitarbeiter/MitarbeiterListe";
import Lagerverwaltung from "./Lager/Lagerverwaltung";
import DeleteIcon from "@mui/icons-material/Delete";
import useDeleteFirma from "./useDeleteFirma";

function FirmenMgmt() {
    const { data: allFirmen, error: errorOnFirmen, loaded: firmenLoaded, updateFirma, triggerReload } = useFirmen();
    const [selectedFirma, setSelectedFirma] = useState<Firma>();

    const { deleting, error: errorOnDelete, deleteFirma } = useDeleteFirma();

    useEffect(() => {
        if (allFirmen) {
            if (allFirmen.length > 0) {
                setSelectedFirma(allFirmen[0]);
            } else {
                setSelectedFirma(undefined);
            }
        }
    }, [allFirmen]);

    const columns: GridColDef<Firma>[] = [
        {
            field: "nummer",
            headerName: "Nummer",
            type: "number",
            editable: true,
            width: 80,
        },
        {
            field: "name",
            headerName: "Name",
            editable: true,
            width: 150,
        },
        {
            field: "bemerkungen",
            headerName: "Bemerkung",
            editable: true,
            width: 220,
        },
        {
            field: "actions",
            type: "actions",
            getActions: (params: GridRowParams<Firma>) => [
                <GridActionsCellItem
                    key={"delete"}
                    showInMenu={false}
                    icon={<DeleteIcon />}
                    onClick={async (p) => {
                        if (window.confirm(`Firma "${params.row.name}" wirklich löschen?`)) {
                            await deleteFirma(params.id.toString());
                            triggerReload();
                        }
                    }}
                    label="Löschen"
                    disabled={(params.row.lager && params.row.lager.length > 0) || (params.row.benutzer && params.row.benutzer.length > 0)}
                />,
            ],
            width: 50,
            resizable: false,
        },
    ];

    const [showCreateFirma, setShowCreateFirma] = useState(false);

    return (
        <div className="contentDiv">
            <Button variant="outlined" onClick={() => setShowCreateFirma(true)}>
                Neue Firma
            </Button>
            <br></br>
            <br></br>
            <CreateFirmaDialog
                show={showCreateFirma}
                onClose={() => {
                    setShowCreateFirma(false);
                    triggerReload();
                }}
            />
            {errorOnFirmen !== "" && (
                <Alert severity="error">
                    <AlertTitle>Fehler beim Laden der Firmen</AlertTitle>
                    {errorOnFirmen}
                </Alert>
            )}
            {errorOnDelete && (
                <Alert severity="error">
                    <AlertTitle>Fehler beim Löschen der Firma</AlertTitle>
                    {errorOnDelete}
                </Alert>
            )}
            <Box
                sx={{
                    flexGrow: 0.8,
                    display: { md: "flex" },
                }}
            >
                <DataGridPro
                    sx={{ flex: 0.5 }}
                    autoHeight
                    density="compact"
                    loading={!firmenLoaded || deleting}
                    processRowUpdate={async (updatedRow, originalRow) => {
                        await updateFirma(updatedRow);
                        return updatedRow;
                    }}
                    rowSelectionModel={selectedFirma ? [selectedFirma.id] : []}
                    onRowClick={(r) => setSelectedFirma(r.row as Firma)}
                    getRowId={(r) => r.id}
                    rows={allFirmen}
                    columns={columns}
                    getRowHeight={() => "auto"}
                    pagination
                    slots={{
                        toolbar: CustomGridToolbar,
                        loadingOverlay: LinearProgress as any,
                    }}
                />
                <div
                    style={{
                        flex: 1.2,
                        border: "1px solid rgba(224, 224, 224, 1)",
                        borderRadius: "4px",
                        marginLeft: "5px",
                        padding: "0 15px 0 15px",
                    }}
                >
                    <h2>{selectedFirma?.name}</h2>
                    {selectedFirma && (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                            <Box sx={{ flex: 2.3 }}>
                                <Lagerverwaltung firmaId={selectedFirma.id} lagerChanged={(l) => {}} />
                            </Box>
                            <Box sx={{ flex: 1.3 }}>
                                <h4>Mitarbeiter</h4>
                                <MitarbeiterListe firmaId={selectedFirma.id} onChange={triggerReload} />
                            </Box>
                        </Box>
                    )}
                </div>
            </Box>
        </div>
    );
}

export default FirmenMgmt;
