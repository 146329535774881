import { AnforderungsTyp } from "../Models/Anforderung";
import { Avatar, Tooltip, Typography } from "@mui/material";
import "./AnforderungsTypIcon.css";

export type AnforderungsTypIconProps = {
    type: AnforderungsTyp;
};
export default function AnforderungsTypIcon(props: AnforderungsTypIconProps) {
    const { type } = props;

    let value = "-";
    let title = "Unbekannt";
    if (type) {
        switch (type) {
            case AnforderungsTyp.Unbekannt:
                break;
            default:
                value = type;
                break;
        }
        let key = Object.keys(AnforderungsTyp).indexOf(type);
        title = Object.values(AnforderungsTyp)[key];
    }

    return (
        <>
            <Tooltip title={title}>
                <Avatar className={type} sizes="10" sx={{ width: 25, height: 25 }}>
                    <Typography className={type} fontSize={12}>
                        {value}
                    </Typography>
                </Avatar>
            </Tooltip>
        </>
    );
}
