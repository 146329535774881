import { useState } from "react";
import axios from "../../../../ApiClient";

import { Benutzer } from "./Benutzer";

const INVITE_USER_URL = "/angelo/logistik/firmen/{id}/mitarbeiter/invitation";

const useInviteBenutzerToFirma = () => {
  const [loaded, setLoaded] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState<Benutzer>({} as Benutzer);

  const inviteBenutzerToFirma = async (firstName: string, lastName: string, email: string, firmaId: number) => {
    try {
      setError("");
      setLoaded(false);
      let request = {
        firstName: firstName,
        lastName: lastName,
        email: email,
      };

      let url = INVITE_USER_URL.replace("{id}", firmaId.toString());

      const response = await axios.post<Benutzer>(url, request);
      const invitedBenutzer = response.data;
      setData(invitedBenutzer);
      return invitedBenutzer;
    } catch (error: any) {
      if (error.response.data.message !== undefined) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
    } finally {
      setLoaded(true);
    }
  };
  return { data, error, loaded, inviteBenutzerToFirma };
};

export default useInviteBenutzerToFirma;
