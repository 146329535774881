import React, { useState } from "react";
import { Lager } from "../Models/Lager";

export interface AppContextInterface {
    cartItemCount: number;
    setCartItemCount: Function;
    cartLager: Lager | undefined;
    setCartLager: (newLager: Lager | undefined) => void;
    amountOffeneAnforderungen: number;
    setAmountOffeneAnforderungen: (newVal: number) => void;
}

export const AppContext = React.createContext<AppContextInterface>({
    cartItemCount: 0,
    setCartItemCount: () => {},
    cartLager: undefined,
    setCartLager: () => {},
    amountOffeneAnforderungen: 0,
    setAmountOffeneAnforderungen: () => {},
});

type AppContextProviderProps = {
    children: JSX.Element[] | JSX.Element;
};

export function AppContextProvider(props: AppContextProviderProps) {
    const [cartItemCount, setCartItemCount] = useState<number>();
    const [cartLager, setCartLager] = useState<Lager>();
    const [amountOffeneAnforderungen, setAmountOffeneAnforderungen] = useState<number>(0);

    let initialValues: AppContextInterface = {
        cartItemCount: cartItemCount ? cartItemCount : 0,
        setCartItemCount: setCartItemCount,
        cartLager: cartLager,
        setCartLager: setCartLager,
        amountOffeneAnforderungen: amountOffeneAnforderungen,
        setAmountOffeneAnforderungen: setAmountOffeneAnforderungen,
    };

    return <AppContext.Provider value={initialValues}>{props.children}</AppContext.Provider>;
}
