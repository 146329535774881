import { useEffect, useState } from "react";
import axios from "../../../ApiClient";
import { Lieferschein } from "../../../Models/Lieferschein";

export const useGetLieferscheinView = (id?: string) => {
  const [data, setData] = useState<Lieferschein>();
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  const LIEFERSCHEIN_URL = "angelo/logistik/lieferschein/{id}";

  useEffect(() => {
    const loadLieferschein = async (id?: string) => {
      if (id) {
        setLoaded(false);
        try {
          const anforderungenResponse = await axios.get<Lieferschein>(LIEFERSCHEIN_URL.replace("{id}", id));
          const anforderungen = anforderungenResponse.data;
          setData(anforderungen);
        } catch (error: any) {
          setError(error.message);
        } finally {
          setLoaded(true);
        }
      }
    };
    loadLieferschein(id);
  }, [id]);

  return { data, error, loaded };
};
