import { useEffect, useState } from "react";
import axios from "../../../ApiClient";
import { ArtikelWithPuffer } from "../../../Models/ArtikelWithPuffer";

const ARTIKEL_URL = "/angelo/firma/{firma}/lager/{lager}/artikelzuweisung";

export const useGetArtikelOfLager = (firmaId: string, lagerId: number | undefined) => {
    const [data, setData] = useState<ArtikelWithPuffer[]>([]);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(true);

    const [refresh, setRefresh] = useState(false);

    const toggleRefresh = () => {
        setRefresh(!refresh);
    };

    useEffect(() => {
        const loadArtikel = async () => {
            console.log("Fetching articles for lager", lagerId);
            if (!lagerId || !firmaId) {
                return;
            }
            setLoaded(false);
            try {
                setError("");
                const response = await axios.get<ArtikelWithPuffer[]>(ARTIKEL_URL.replace("{firma}", firmaId).replace("{lager}", lagerId.toString()));
                const json = response.data;
                setData(json);
            } catch (error: any) {
                console.log("Error on fetching articles:", error);
                setError(error.message);
            } finally {
                setLoaded(true);
            }
        };
        loadArtikel();
    }, [refresh, lagerId, firmaId]);

    return { data, error, loaded, toggleRefresh };
};
