import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { useContext, useEffect } from "react";
import { Link as RouterLink, useMatch } from "react-router-dom";
import useGetAnforderungenOfFirma from "../applications/bestellung/OffeneAnforderungen/useGetAnforderungenOfFirma";
import UserProfile from "./UserProfile";
import { AppContext } from "../context/AppContext";
import { BearbeitungsStatus } from "../Models/Anforderung";
import { useWarenkorb } from "./../applications/bestellung/Warenkorb/useWarenkorb";
import useAuthentication from "./../authentication/useAuthentication";
import { UserRoles } from "./../Models/User";

export const LOGISTIK_ANFORDERUNGEN_PATH = "/logistik/anforderungsbearbeitung/";
export const LOGISTIK_HISTORIE_PATH = "/logistik/historie/";

export const BESTELLUNG_HISTORIE_PATH = "/bestellung/anforderungshistorie/";
export const BESTELLUNG_ANFORDERUNGEN_PATH = "/bestellung/offeneAnforderungen/";

const reqStatus: BearbeitungsStatus[] = [BearbeitungsStatus.Offen, BearbeitungsStatus.InKommissionierung, BearbeitungsStatus.Versendet, BearbeitungsStatus.Teillieferung];

function Navigation() {
    const { isAuthenticated, hasRole, firmaIdAnforderer } = useAuthentication();

    const { cartItemCount: cartItems, setCartItemCount: setCartItems, amountOffeneAnforderungen, setAmountOffeneAnforderungen } = useContext(AppContext);

    const { entries } = useWarenkorb();

    const { data } = useGetAnforderungenOfFirma(firmaIdAnforderer, reqStatus);

    useEffect(() => {
        setCartItems(entries.length);
    }, [entries, setCartItems]);

    useEffect(() => {
        setAmountOffeneAnforderungen(data.results.length);
    }, [data]);

    const isLager = useMatch("/logistik/lager");
    const isHistorie = useMatch(LOGISTIK_HISTORIE_PATH + "/*");
    const isAnforderungsBearbeitung = useMatch(LOGISTIK_ANFORDERUNGEN_PATH + "/*");
    const isFirmen = useMatch("/logistik/firmen");
    const isArtikelverwaltung = useMatch("/logistik/artikelverwaltung");
    const isMeineFirma = useMatch("/bestellung/meineFirma");
    const isArtikel = useMatch("/bestellung/artikel");
    const isWarenkorb = useMatch("/bestellung/warenkorb");
    const isOffeneAnforderungen = useMatch(BESTELLUNG_ANFORDERUNGEN_PATH + "/*");
    const isAnforderungsHistorie = useMatch(BESTELLUNG_HISTORIE_PATH + "/*");
    const isLagerBestand = useMatch("/bestellung/lagerbestand");
    const isLogistikRoute = useMatch("/logistik/*");
    const isBestellungRoute = useMatch("/bestellung/*");
    const isAdminRoute = useMatch("/administration/*");

    const isAdminUserMgmt = useMatch("/administration/users");

    const isLieferscheinDrucken = useMatch("/logistik/lieferschein/*");

    if (isLieferscheinDrucken) {
        return (
            <AppBar position="static">
                <Toolbar>{hasRole(UserRoles.KOMMISIONIERER) && <Button sx={{ my: 2, color: "white", display: "block" }}>Lieferschein</Button>}</Toolbar>
            </AppBar>
        );
    }

    return (
        <AppBar color={isLogistikRoute || isAdminRoute ? "secondary" : "primary"} position="static">
            <Toolbar>
                <Box sx={{ flexGrow: 1, display: { md: "flex" } }}>
                    {isAuthenticated && hasRole(UserRoles.ANFORDERER) && isBestellungRoute && (
                        <>
                            <Button
                                component={RouterLink}
                                to="/bestellung/artikel"
                                className={isArtikel ? "activeLink" : ""}
                                key="artikel"
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                Artikel
                            </Button>
                            <Button
                                component={RouterLink}
                                to="/bestellung/warenkorb"
                                className={isWarenkorb ? "activeLink" : ""}
                                key="warenkorb"
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                Warenkorb ({cartItems})
                            </Button>
                            <Button
                                component={RouterLink}
                                to={BESTELLUNG_ANFORDERUNGEN_PATH}
                                className={isOffeneAnforderungen ? "activeLink" : ""}
                                key="offeneAnforderungen"
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                Offene Anforderungen ({amountOffeneAnforderungen})
                            </Button>
                            <Button
                                component={RouterLink}
                                to={BESTELLUNG_HISTORIE_PATH}
                                className={isAnforderungsHistorie ? "activeLink" : ""}
                                key="anforderungsHistorie"
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                Anforderungshistorie
                            </Button>

                            <Button
                                component={RouterLink}
                                to="/bestellung/meineFirma"
                                className={isMeineFirma ? "activeLink" : ""}
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                Meine Firma
                            </Button>
                            <Button
                                component={RouterLink}
                                to="/bestellung/lagerbestand"
                                className={isLagerBestand ? "activeLink" : ""}
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                Lagerbestand
                            </Button>
                        </>
                    )}
                    {isAuthenticated && hasRole(UserRoles.KOMMISIONIERER) && (isLogistikRoute || isAdminRoute) && (
                        <>
                            <Button
                                component={RouterLink}
                                to={LOGISTIK_ANFORDERUNGEN_PATH}
                                className={isAnforderungsBearbeitung ? "activeLink" : ""}
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                Abrufbearbeitung
                            </Button>
                            <Button component={RouterLink} to={LOGISTIK_HISTORIE_PATH} className={isHistorie ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                                Abrufhistorie
                            </Button>
                            <Button component={RouterLink} to="/logistik/firmen" className={isFirmen ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                                Firmenverwaltung
                            </Button>
                            <Button
                                component={RouterLink}
                                to="/logistik/artikelverwaltung"
                                className={isArtikelverwaltung ? "activeLink" : ""}
                                key="artikelverwaltung"
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                Artikelverwaltung
                            </Button>
                            <Button component={RouterLink} to="/logistik/lager" className={isLager ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                                Lager
                            </Button>
                        </>
                    )}
                    {isAuthenticated && hasRole(UserRoles.ADMINISTRATOR) && (
                        <Button component={RouterLink} to="/administration/users" className={isAdminUserMgmt ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                            Benutzerverwaltung
                        </Button>
                    )}
                </Box>
                <UserProfile />
            </Toolbar>
        </AppBar>
    );
}

export default Navigation;
