import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import useDeleteById from "../hooks/useDeleteById";
import ErrorDisplay from "../components/ErrorDisplay";
import InfoDisplay from "../components/InfoDisplay";
import User, { UserTypes } from "../../Models/User";

type DeleteUserDialogProps = {
    user: User;
    onChanged: () => void;
    onClosed: () => void;
};
export default function DeleteUserDialog(props: Readonly<DeleteUserDialogProps>) {
    const { user, onClosed, onChanged } = props;
    const [showForm, setShowForm] = useState(false);
    const { data, deleteObject, error, isLoading } = useDeleteById<string>("angelo/admin/users");

    const close = () => {
        setShowForm(false);
        onClosed();
    };

    const deleteUser = async () => {
        await deleteObject(user.id.toString());
    };

    useEffect(() => {
        if (data) {
            onChanged();
            close();
        }
    }, [data]);

    useEffect(() => {
        if (user) {
            setShowForm(true);
        }
    }, [user]);

    return (
        <Dialog open={showForm} onClose={() => close()}>
            <DialogTitle>Benutzer {user.displayName} löschen</DialogTitle>
            <DialogContent>
                Soll der Benutzer: {user.email} wirklich gelöscht werden?
                {user.type === UserTypes.INTERN && <InfoDisplay title="ACHTUNG!" message="Interne Nutzer müssen zusätzlich aus dem AZURE Portal gelöscht werden!" />}
                {error && <ErrorDisplay title="Fehler beim löschen des Benutzers" error={error} />}
                {isLoading && <LinearProgress />}
            </DialogContent>
            <DialogActions>
                <Button color="error" disabled={isLoading} onClick={() => deleteUser()} variant="outlined" size="small" startIcon={<SaveIcon fontSize="small" />}>
                    Löschen
                </Button>
                <Button disabled={isLoading} onClick={() => close()} variant="outlined" size="small" startIcon={<CloseIcon fontSize="small" />}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
