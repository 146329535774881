import { MouseEventHandler, useEffect, useState } from "react";
import axios from "./../ApiClient";

export type AuthenticatedImgProps = {
    src: string;
    alt: string;
    inList: boolean;
    onClick?: MouseEventHandler<HTMLImageElement>;
};

export default function AuthenticatedImg(props: AuthenticatedImgProps) {
    const { src, alt, onClick, inList } = props;
    const [imgData, setImgData] = useState<string | ArrayBuffer | null>();

    let [img, setImg] = useState<JSX.Element>(<></>);

    useEffect(() => {
        let retrieveImgData = async () => {
            let response = await axios.get(src, {
                responseType: "blob",
            });

            if (response.status === 200) {
                let reader = new window.FileReader();
                reader.readAsDataURL(response.data);
                reader.onloadend = function () {
                    let base64data = reader.result;
                    setImgData(base64data);
                };
            }
        };

        retrieveImgData();
    }, [src]);

    useEffect(() => {
        if (imgData) {
            let img = <img src={imgData?.toString()} alt={alt} onClick={onClick} height={inList ? "34px" : "100%"}></img>;
            setImg(img);
        }
    }, [imgData, alt, inList, onClick]);

    return img;
}
