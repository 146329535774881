import { useCallback, useState } from "react";
import axios from "../../../../ApiClient";
import { Artikel } from "../../../../Models/Artikel";

const ARTIKEL_URL = "/angelo/logistik/artikel/";

export const useUpdateArtikel = () => {
    const [updateError, setUpdateError] = useState("");
    const [updateLoaded, setUpdateLoaded] = useState(true);

    const updateArtikel = useCallback(async (artikel: Artikel) => {
        setUpdateLoaded(false);
        setUpdateError("");
        try {
            await axios.put(ARTIKEL_URL + artikel.id, artikel);
            return true;
        } catch (deactivateError: any) {
            console.log("Error deactivating article:", deactivateError);
            setUpdateError(deactivateError.message);
            return false;
        } finally {
            setUpdateLoaded(true);
        }
    }, []);

    return { updateArtikel, updateError, updateLoaded };
};
