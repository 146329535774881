import { useEffect, useState } from "react";
import axios from "../../../ApiClient";
import { Firma } from "../../../Models/Firma";

const FIRMEN_URL = "/angelo/logistik/firmen";
const FIRMA_URL = "/angelo/logistik/firmen/{id}";

export type UpdateFirmaRequest = {
    id: number;
    nummer: string;
    name: string;
    bemerkungen: string;
};

export type CreateFirmaRequest = {
    nummer: string;
    name: string;
    bemerkungen: string;
};

const useFirmen = () => {
    const [data, setData] = useState<Firma[]>([]);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [reload, setReload] = useState(true);

    const triggerReload = () => {
        setReload((reload) => !reload);
    };

    const createFirma = async (createFirmaRequest: CreateFirmaRequest) => {
        try {
            let createResult = await axios.post<Firma>(FIRMEN_URL, createFirmaRequest);
            return createResult.data;
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoaded(true);
        }
    };

    const updateFirma = async (updateFirmaRequest: UpdateFirmaRequest) => {
        try {
            await axios.put<Firma>(FIRMA_URL.replace("{id}", updateFirmaRequest.id.toString()), updateFirmaRequest);
            triggerReload();
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoaded(true);
        }
    };

    useEffect(() => {
        const loadFirmen = async () => {
            setLoaded(false);
            try {
                const firmenResponse = await axios.get<Firma[]>(FIRMEN_URL);
                const firmen = firmenResponse.data;
                setData(firmen);
            } catch (error: any) {
                setError(error.message);
            } finally {
                setLoaded(true);
            }
        };
        loadFirmen();
    }, [reload]);
    return { data, error, loaded, updateFirma, createFirma, triggerReload };
};

export default useFirmen;
