import { useCallback, useState } from "react";
import axios from "../../../../ApiClient";

const ANFORDERUNG_ABSCHLUSS_URL = "/angelo/logistik/anforderungen/{id}/kommissionierung";

export type KommissioniertePosition = {
  anforderungsPositionId: string;
  kommissionierteAnzahl: number;
};

export const useKommissioniereAnforderung = () => {
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(true);

  const anforderungKommissionieren = useCallback(
    async (
      anforderungId: string,
      isuLieferschein: string,
      kommentar: string,
      kommissionierungen: KommissioniertePosition[]
    ) => {
      setLoaded(false);
      try {
        await axios.post(ANFORDERUNG_ABSCHLUSS_URL.replace("{id}", anforderungId), {
          isuLieferschein: isuLieferschein,
          kommentar: kommentar,
          kommissioniertePositionen: kommissionierungen,
        });
        return true;
      } catch (deactivateError: any) {
        console.log("Error patching anforderung status", deactivateError);
        setError(deactivateError.message);
        return false;
      } finally {
        setLoaded(true);
      }
    },
    []
  );

  return { anforderungKommissionieren, error, loaded };
};
