import { Alert, LinearProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import LieferscheinDruckenView from "./LieferscheinDruckenView";
import { useGetLieferscheinView } from "./usetGetLieferscheinView";
import "./LieferscheinDrucken.css";

export default function LieferscheinDrucken() {
  const { id } = useParams();

  const { data, error, loaded } = useGetLieferscheinView(id);

  return (
    <div className="printContent">
      {error !== "" && <Alert severity="error">{error}</Alert>}
      {!loaded && <LinearProgress />}
      {loaded && data && <LieferscheinDruckenView lieferschein={data} />}
    </div>
  );
}
