import { useEffect, useState } from "react";
import useLagerOfFirma from "./useLagerOfFirma";
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { Lager } from "@/Models/Lager";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Alert, Button, LinearProgress } from "@mui/material";
import CustomGridToolbar from "../../../../layout/CustomGridToolbar";
import AddLagerToFirmaDialog from "./AddLagerToFirmaDialog";

type LagerListeProps = {
    firmaId: number;
    lagerChanged: (lager: Lager | undefined) => void;
};
export default function LagerListe(props: Readonly<LagerListeProps>) {
    const { firmaId, lagerChanged } = props;

    const { error, loaded, data: lagerOfFirma, setFirmenLager, getLagerOfFirma } = useLagerOfFirma();
    const [selectedLager, setSelectedLager] = useState<Lager | undefined>();

    const [firmaToAddLagerTo, setFirmaToAddLagerTo] = useState<number>();

    useEffect(() => {
        lagerChanged(selectedLager);
    }, [selectedLager]);

    useEffect(() => {
        if (firmaId) {
            getLagerOfFirma(firmaId);
            setSelectedLager(undefined);
        }
    }, [firmaId]);

    const columns: GridColDef<Lager>[] = [
        {
            field: "actions",
            type: "actions",
            width: 50,
            getActions: (p: GridRowParams<Lager>) => {
                return [
                    <GridActionsCellItem
                        key="entfernen"
                        showInMenu={false}
                        icon={<HighlightOffIcon color="warning" fontSize="small" titleAccess="Entfernen" />}
                        label="Löschen"
                        onClick={async (r) => {
                            let filteredLagerNummern = lagerOfFirma.filter((l) => l.id !== p.id).map((l) => l.nummer);
                            await setFirmenLager(firmaId, filteredLagerNummern);
                        }}
                    />,
                ];
            },
        },
        {
            field: "nummer",
            headerName: "Nummer",
            width: 100,
        },
        {
            field: "name",
            headerName: "Name",
            width: 160,
        },
    ];

    return (
        <span>
            {error !== "" && (
                <Alert severity="error">
                    <span>{"Ein Fehler ist aufgetreten! " + error}</span>
                </Alert>
            )}

            <Button
                sx={{ margin: 1 }}
                size="small"
                variant="outlined"
                onClick={() => {
                    setFirmaToAddLagerTo(firmaId);
                }}
            >
                Lager hinzufügen
            </Button>
            <DataGridPro
                autoHeight
                density="compact"
                loading={!loaded}
                columns={columns}
                getRowId={(r) => r.id}
                rows={lagerOfFirma}
                onRowClick={(r) => setSelectedLager(r.row as Lager)}
                getRowHeight={() => "auto"}
                slots={{
                    toolbar: CustomGridToolbar,
                    loadingOverlay: LinearProgress as any,
                }}
            />

            <AddLagerToFirmaDialog
                firmaId={firmaToAddLagerTo}
                assignedLager={lagerOfFirma}
                onClose={() => {
                    setFirmaToAddLagerTo(undefined);
                    getLagerOfFirma(props.firmaId);
                }}
            ></AddLagerToFirmaDialog>
        </span>
    );
}
