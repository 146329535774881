import { useCallback, useState } from "react";
import axios from "../../../../ApiClient";

const ANFORDERUNG_URL = "/angelo/logistik/anforderungen/";

export const useUpdateAnforderungBemerkung = () => {
  const [updateError, setUpdateError] = useState("");
  const [updateLoaded, setUpdateLoaded] = useState(true);

  const updateAnforderungBemerkung = useCallback(async (anforderungId: string, newBemerkung: string) => {
    setUpdateLoaded(false);
    try {
      await axios.put(ANFORDERUNG_URL + anforderungId, { newBemerkung: newBemerkung });
      return true;
    } catch (deactivateError: any) {
      console.log("Error patching anforderung bemerkung", deactivateError);
      setUpdateError(deactivateError.message);
      return false;
    } finally {
      setUpdateLoaded(true);
    }
  }, []);

  return { updateAnforderungBemerkung, updateError, updateLoaded };
};
