import { LinearProgress, List, ListItemText, Grid, Alert, ListItem } from "@mui/material";
import { Lager } from "../../../Models/Lager";
import useGetById from "../../../applications/hooks/useGetById";
import useGetLagerOfFirma from "./useGetLagerOfFirma";
import useAuthentication from "./../../../authentication/useAuthentication";
import { UserRoles } from "./../../../Models/User";
import { Firma } from "@/Models/Firma";
import { useEffect } from "react";
import ErrorDisplay from "../../../applications/components/ErrorDisplay";

export default function MeineFirma() {
    const { hasRole, firmaIdAnforderer } = useAuthentication();
    const { data: firma, error, isLoading, loadById } = useGetById<Firma>("angelo/firmen");

    useEffect(() => {
        if (firmaIdAnforderer) {
            loadById(firmaIdAnforderer);
        }
    }, [firmaIdAnforderer]);

    return (
        <div className="contentDiv">
            {error && <ErrorDisplay title="Firma konnte nicht geladen werden" error={error} />}
            {isLoading && <LinearProgress />}
            {!hasRole(UserRoles.ANFORDERER) && <ErrorDisplay title="Firma kann nicht geladen werden" error="Diese Funktion besteht nur für Anforderer!" />}
            {hasRole(UserRoles.ANFORDERER) && (
                <>
                    <h1>Firma</h1>
                    <Grid container>
                        <Grid item xs={1}>
                            Nummer:
                        </Grid>
                        <Grid item>{firma?.nummer}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={1}>
                            Name:
                        </Grid>
                        <Grid item>{firma?.name}</Grid>
                    </Grid>

                    <h1>Registrierte Mitarbeiter</h1>
                    <List dense={true}>
                        {firma?.benutzer.map((b, index) => (
                            <ListItemText key={index}>{b.email}</ListItemText>
                        ))}
                    </List>

                    <h1>Lager</h1>
                    <LagerListe firma={firmaIdAnforderer} />
                </>
            )}
        </div>
    );
}

export function LagerListe(props: { firma: string }) {
    const { data, error, isLoading } = useGetLagerOfFirma(props.firma);

    return (
        <>
            {error !== "" && (
                <Alert severity="error">
                    <span>{"Ein Fehler ist aufgetreten! " + error}</span>
                </Alert>
            )}
            {isLoading && <LinearProgress></LinearProgress>}
            {data && (
                <List>
                    {data.map((l: Lager, id: number) => {
                        return (
                            <ListItem key={id}>
                                {l.name} ({l.nummer})
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </>
    );
}
