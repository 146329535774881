import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField } from "@mui/material";
import { AnforderungsPosition } from "@/Models/AnforderungsPosition";
import Storno from "@/Models/Storno";
import usePost from "../../../hooks/usePost";

type StornoButtonProps = {
    position: AnforderungsPosition;
    nochOffen: number;
    stornoCallback: (posId: string) => void;
};
export default function StornoButton(props: StornoButtonProps) {
    const { position, nochOffen, stornoCallback } = props;
    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState<string>("");

    const { data, error, isLoading, createObject } = usePost<Storno>("angelo/logistik/storno");

    const handleClose = () => {
        setOpen(false);
    };

    const storno = async () => {
        let storno = {
            anforderungsPosition: position,
            stornierteAnzahl: nochOffen,
            stornierungsgrund: comment,
        } as Storno;
        await createObject(storno);
    };

    useEffect(() => {
        setComment("");
    }, [position]);

    useEffect(() => {
        if (data) {
            stornoCallback(position.id);
            setOpen(false);
        }
    }, [data]);

    if (nochOffen === 0) {
        return <></>;
    }

    if (!open) {
        return (
            <Button size="small" variant="outlined" startIcon={<ClearIcon />} onClick={() => setOpen(true)}>
                Storno
            </Button>
        );
    }

    if (open) {
        return (
            <Dialog onClose={handleClose} open={open} maxWidth="md">
                {isLoading && <LinearProgress />}
                {error && (
                    <Alert severity="error">
                        <AlertTitle>Fehler beim Stornieren</AlertTitle>
                        {error}
                    </Alert>
                )}
                <DialogTitle>Artikel "{position.artikel.name}" stornieren</DialogTitle>
                <DialogContent>
                    Der Artikel "{position.artikel.name}" (Menge: {nochOffen}) wird aus der Bestellung storniert. Bitte einen Kommentar mit Stornogrund eingeben.
                    <TextField
                        fullWidth
                        sx={{ marginTop: 3 }}
                        label="Kommentar"
                        required={true}
                        value={comment}
                        onChange={(evt) => setComment(evt.currentTarget.value)}
                    ></TextField>
                </DialogContent>
                <DialogActions>
                    <Button size="small" onClick={handleClose} variant="contained">
                        Abbrechen
                    </Button>
                    <Button size="small" onClick={() => storno()} variant="contained" disabled={comment === "" || isLoading}>
                        Stornieren
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    return <></>;
}
