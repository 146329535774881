import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, signUpRequest } from "./msalConfig";

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback((message) => {
    if (message.eventType === EventType.LOGIN_FAILURE) {
        if (message.error?.message.includes("AADB2C90118")) {
            // The user has forgotten his password.
            let redirect = window.location.href;
            console.log("call rediret url", process.env.REACT_APP_MSAL_PW_RESET_URL + `&redirect_uri=${redirect}`);
            window.location.href = process.env.REACT_APP_MSAL_PW_RESET_URL + `&redirect_uri=${redirect}`;
        }
    }
    if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        if (message.error?.message.includes("AADB2C90077")) {
            // Token expired:  interaction_required: AADB2C90077: User does not have an existing session and request prompt parameter has a value of 'None'
            let redirect = window.location.href;

            msalInstance.logoutRedirect({
                postLogoutRedirectUri: redirect,
            });
        }
    }
    // internal user must signup before signin
    if (message.eventType === EventType.LOGIN_FAILURE) {
        if (message.error?.message.includes("AADB2C99002")) {
            msalInstance.loginRedirect(signUpRequest);
        }
    }
});

export default msalInstance;
