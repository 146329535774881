import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, TextField } from "@mui/material";
import { useCallback, useState } from "react";

import { Artikel } from "../../../../Models/Artikel";
import { useCreateArtikel } from "./useCreateArtikel";
import { useSetArtikelImage } from "./useSetArtikelImage";

type CreateArtikelDialogProps = {
    show: boolean;
    onClose: Function;
};

function CreateArtikelDialog(props: CreateArtikelDialogProps) {
    const [userInput, setUserInput] = useState<{ [k: string]: any }>({});
    const { createArtikel, error, loaded } = useCreateArtikel();
    const { setArtikelImage, error: imageError, loaded: imageLoaded } = useSetArtikelImage();

    function updateProperty(prop: string, val: any) {
        let dataClone = { ...userInput };
        dataClone[prop] = val;
        setUserInput(dataClone);
    }

    const handleClose = useCallback(() => {
        setUserInput({});
        props.onClose();
    }, [props, setUserInput]);

    const handleSave = useCallback(async () => {
        const newArtikel = { ...userInput } as Artikel;

        let createdArtikelId = await createArtikel(newArtikel);
        if (createdArtikelId && userInput["artikelImage"]) {
            let imageCreated = await setArtikelImage(createdArtikelId, userInput["artikelImage"]);
            if (imageCreated) {
                handleClose();
            }
        } else if (createdArtikelId) {
            handleClose();
        }
    }, [error, createArtikel, userInput, handleClose, imageError, setArtikelImage]);

    return (
        <Dialog open={props.show} onClose={() => handleClose()}>
            <DialogTitle>Neuer Artikel</DialogTitle>
            {(!loaded || !imageLoaded) && <LinearProgress></LinearProgress>}
            <DialogContent>
                <DialogContentText>
                    Bitte gib alle Daten für einen neuen Artikel ein
                    {error !== "" || imageError !== "" ? (
                        <>
                            <br></br>
                            <span style={{ color: "red" }}>
                                Ein Fehler ist aufgetreten: {error} {imageError}
                            </span>
                        </>
                    ) : null}
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    onChange={(ev) => updateProperty("name", ev.currentTarget.value)}
                />
                <TextField margin="dense" id="art" label="Art" type="text" fullWidth variant="standard" required onChange={(ev) => updateProperty("art", ev.currentTarget.value)} />
                <TextField
                    margin="dense"
                    id="plangrundtyp"
                    label="Plangrundtyp"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    onChange={(ev) => updateProperty("planGrundTyp", ev.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    id="planschluessel"
                    label="Planschluessel"
                    type="number"
                    fullWidth
                    variant="standard"
                    required
                    onChange={(ev) => updateProperty("planSchluessel", ev.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    id="bauklasse"
                    label="Bauklasse"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    onChange={(ev) => updateProperty("bauklasse", ev.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    id="funktionsklasse"
                    label="Funktionsklasse"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    onChange={(ev) => updateProperty("funktionsKlasse", ev.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    id="planungsjahr"
                    label="Planungsjahr"
                    type="number"
                    fullWidth
                    variant="standard"
                    required
                    onChange={(ev) => updateProperty("planungsjahr", ev.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    id="bemerkung"
                    label="Bemerkung"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(ev) => updateProperty("bemerkung", ev.currentTarget.value)}
                />
                <input accept="image/*" style={{ display: "none" }} id="imageFile" type="file" onChange={(ev) => updateProperty("artikelImage", ev.currentTarget.files![0])} />
                <label htmlFor="imageFile">
                    <Button variant="outlined" component="span" style={{ marginTop: "15px" }}>
                        Bild auswählen
                        {userInput["artikelImage"] ? "(" + userInput["artikelImage"].name + ")" : ""}
                    </Button>
                </label>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Abbrechen</Button>
                <Button onClick={handleSave}>Speichern</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateArtikelDialog;
