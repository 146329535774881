import { useState } from "react";
import axios from "../../../../ApiClient";

import { Benutzer } from "./Benutzer";

const UPDATE_USER_URL = "/angelo/logistik/firmen/{id}/mitarbeiter/{email}";
const USER_URL = "/angelo/logistik/firmen/{id}/mitarbeiter";

type BenutzerResponse = {
  firmaId: number;
  benutzer: Benutzer[];
};

const useMitarbeiterOfFirma = () => {
  const [loaded, setLoaded] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState<Benutzer[]>([]);

  const getMitarbeiter = async (firmaId: number) => {
    try {
      setError("");
      setLoaded(false);

      let url = USER_URL.replace("{id}", firmaId.toString());

      const response = await axios.get<BenutzerResponse>(url);
      const allBenutzer = response.data.benutzer;
      setData(allBenutzer);
      return allBenutzer;
    } catch (error: any) {
      if (error.response.data.message !== undefined) {
        setError(error.response.data.message);
        return;
      }
      setError(error.message);
    } finally {
      setLoaded(true);
    }
  };

  const updateMitarbeiter = async (firmaId: number, email: string, isActive: boolean) => {
    try {
      setError("");
      setLoaded(false);
      let request = {
        isActive: isActive,
        email: email,
      };

      let url = UPDATE_USER_URL.replace("{id}", firmaId.toString()).replace("{email}", email);

      const response = await axios.put<BenutzerResponse>(url, request);
      const invitedBenutzer = response.data.benutzer;
      setData(invitedBenutzer);
      return invitedBenutzer;
    } catch (error: any) {
      if (error.response.data.message !== undefined) {
        setError(error.response.data.message);
        return;
      }
      setError(error.message);
    } finally {
      setLoaded(true);
    }
  };
  return { data, error, loaded, updateMitarbeiter, getMitarbeiter };
};

export default useMitarbeiterOfFirma;
