import { useEffect, useState } from "react";
import axios from "../../../ApiClient";
import { Lager } from "../../../Models/Lager";

const VIEW_LAGER_URL = "/angelo/firmen/{firmaId}/lager";

const useGetLagerOfFirma = (firmaId: string) => {
    const [data, setData] = useState<Lager[]>([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadLager = async () => {
            setError("");
            try {
                setIsLoading(true);
                if (firmaId === undefined || firmaId === "") {
                    return;
                }
                const response = await axios.get<Lager[]>(VIEW_LAGER_URL.replace("{firmaId}", firmaId));
                const json = response.data;
                setData(json);
            } catch (error: any) {
                console.log("Fehler beim Laden des Lagers", error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        loadLager();
    }, [firmaId]);
    return { data, error, isLoading };
};

export default useGetLagerOfFirma;
