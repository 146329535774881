import { Alert, AlertTitle } from "@mui/material";

type ErrorDisplayProps = {
    title: string;
    message: string;
};
export default function InfoDisplay(props: Readonly<ErrorDisplayProps>) {
    const { title, message } = props;
    return (
        <Alert severity="info">
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    );
}
