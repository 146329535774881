import axios from "../../../../ApiClient";
import { useCallback, useState } from "react";
import { KommissionierungsStatus } from "../../../../Models/Kommissionierung";

const KOMMISSIONIERUNGS_URL = "/angelo/logistik/kommissionierungen/";

export const useUpdateKommissionierungsStatus = () => {
  const [updateError, setUpdateError] = useState("");
  const [updateLoaded, setUpdateLoaded] = useState(true);

  const updateKommissionierungsStatus = useCallback(
    async (kommissionierungsId: string, newStatus: KommissionierungsStatus) => {
      setUpdateLoaded(false);
      try {
        await axios.put(KOMMISSIONIERUNGS_URL + kommissionierungsId + "/status", { newStatus: newStatus });
        return true;
      } catch (deactivateError: any) {
        console.log("Error patching KommissionierungsStatus", deactivateError);
        setUpdateError(deactivateError.message);
        return false;
      } finally {
        setUpdateLoaded(true);
      }
    },
    []
  );

  return { updateKommissionierungsStatus, updateError, updateLoaded };
};
