import { useEffect, useState } from "react";
import axios from "../../../../ApiClient";
import { ArtikelWithPuffer } from "../../../../Models/ArtikelWithPuffer";

const ARTIKEL_OF_LAGER_URL = "/angelo/logistik/lager/{id}/artikelzuweisung";

export type FirmaArtikelResponse = {
  firmaId: string;
  artikel: ArtikelWithPuffer[];
};

const useGetArtikelOfLager = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ArtikelWithPuffer[]>([] as ArtikelWithPuffer[]);
  const [error, setError] = useState<string>("");
  const [id, setId] = useState<string>();
  const [reloadSwitch, setReloadSwitch] = useState<boolean>(true);

  const loadData = async () => {
    if (id) {
      setError("");
      setLoading(true);
      try {
        const artikelResponse = await axios.get<FirmaArtikelResponse>(ARTIKEL_OF_LAGER_URL.replace("{id}", id));
        const artikel = artikelResponse.data.artikel.sort((a, b) => {
          return a.artikelnummer.localeCompare(b.artikelnummer);
        });
        setData(artikel);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id, reloadSwitch]);

  return { loading, data, error, setId, setReloadSwitch };
};

export default useGetArtikelOfLager;
