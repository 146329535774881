import { Button } from "@mui/material";
import Header from "../layout/Header";
import Navigation from "../layout/Navigation";
import useAuthentication from "./useAuthentication";
import { UserRoles } from "../Models/User";

type SecuredPageProps = {
    children: JSX.Element;
    hideHeaders?: boolean;
};

function SecuredPageLogistik(props: Readonly<SecuredPageProps>) {
    const { children, hideHeaders } = props;
    const { isAuthenticated, hasRole, logout } = useAuthentication();

    if (!isAuthenticated || !hasRole(UserRoles.KOMMISIONIERER)) {
        return (
            <>
                <Header />
                <Navigation />
                <div className="contentDiv">
                    Die von dir aufgerufene Seite ist nur für Logistikmitarbeiter (Kommissionierer) zugänglich!
                    <br /> Du bist nicht authorisiert diese Seite zu betreten!
                    <p>Bitte stelle sicher, dass du über die benötigte Rolle verfügst.</p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            logout();
                        }}
                    >
                        Neu anmelden für Gerätelogistik
                    </Button>
                </div>
            </>
        );
    } else {
        return (
            <>
                {!hideHeaders && (
                    <>
                        <Header />
                        <Navigation />
                    </>
                )}
                {children}
            </>
        );
    }
}
export default SecuredPageLogistik;
