import { useEffect, useState } from "react";
import axios from "../../../../ApiClient";
import { ArtikelZuweisungRequest } from "./ArtikelZuweisungRequest";

const ARTIKEL_OF_LAGER_URL = "/angelo/logistik/lager/{id}/artikelzuweisung";

export default function usePutArtikelZuweisungen(lagerid: number) {
    const [result, setResult] = useState();
    const [saving, setSaving] = useState<boolean>(false);
    const [putError, setPutError] = useState<string>("");

    useEffect(() => {
        setResult(undefined);
        setPutError("");
    }, [lagerid]);

    const update = async (zuweisungen: ArtikelZuweisungRequest[]) => {
        setPutError("");
        setResult(undefined);
        if (zuweisungen.length > 0) {
            setSaving(true);
            try {
                let res = await axios.put(ARTIKEL_OF_LAGER_URL.replace("{id}", lagerid.toString()), zuweisungen);
                setResult(res.data);
            } catch (error: any) {
                console.log("Error", error);
                setPutError(error.message);
            } finally {
                setSaving(false);
            }
        }
    };

    return { result, saving, putError, update };
}
