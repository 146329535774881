import { useCallback, useEffect, useState } from "react";
import { BearbeitungsStatus } from "@/Models/Anforderung";
import axios from "../../../ApiClient";
import AnforderungsResult from "../../logistik/anforderungen/AnforderungsResult";
import { AnforderungsQueryFilter, defaultFilter, SortItem } from "../../logistik/anforderungen/inbearbeitung/useGetAnforderungen";

const FIRMA_URL = "/angelo/firmen/{firmaId}/anforderungen";

const useGetAnforderungenOfFirma = (firmaId: string, status?: BearbeitungsStatus[]) => {
    const [refresh, setRefresh] = useState(false);
    const [data, setData] = useState<AnforderungsResult>({
        count: 0,
        page: 0,
        pageSize: 0,
        results: [],
    } as AnforderungsResult);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [filter, setFilter] = useState<AnforderungsQueryFilter>(defaultFilter);
    const [sort, setSort] = useState<SortItem>();

    const getPaginationQuery = useCallback(() => {
        return `page=${filter.page}&pageSize=${filter.pageSize}`;
    }, [filter]);

    const getSortQuery = useCallback(() => {
        if (sort && sort.field && sort.sort && (sort.sort === "asc" || sort.sort === "desc")) {
            return `&sort=${sort.field}&sortOrder=${sort.sort}`;
        }
        return "";
    }, [sort]);

    const getStatusQuery = useCallback(() => {
        if (status) {
            return (
                "&" +
                status
                    .map((elem) => {
                        return `status=${elem}`;
                    })
                    .join("&")
            );
        }
        return "";
    }, [status]);

    useEffect(() => {
        const loadAnforderungen = async () => {
            if (firmaId !== undefined && firmaId !== "") {
                setLoaded(false);
                try {
                    let query = `?${getPaginationQuery()}${getStatusQuery()}${getSortQuery()}&search=${filter.search}`;
                    const anforderungenResponse = await axios.get<AnforderungsResult>(FIRMA_URL.replace("{firmaId}", firmaId).concat(query));
                    const anforderungen = anforderungenResponse.data;
                    setData(anforderungen);
                } catch (error: any) {
                    setError(error.message);
                } finally {
                    setLoaded(true);
                }
            }
        };
        loadAnforderungen();
    }, [refresh, filter, sort, getPaginationQuery, getStatusQuery, getSortQuery, firmaId]);

    const refreshAnforderungen = () => {
        setRefresh((curr) => !curr);
    };

    return { data, error, loaded, setFilter, setSort, refreshAnforderungen };
};

export default useGetAnforderungenOfFirma;
