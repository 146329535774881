import { Alert, AlertTitle } from "@mui/material";

type ErrorDisplayProps = {
    title: string;
    error: string;
};
export default function ErrorDisplay(props: Readonly<ErrorDisplayProps>) {
    const { title, error } = props;
    return (
        <Alert severity="error">
            <AlertTitle>{title}</AlertTitle>
            {error}
        </Alert>
    );
}
