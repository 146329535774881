import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import useGetLagerOfFirma from "../MeineFirma/useGetLagerOfFirma";
import { Alert, AlertTitle, Button, LinearProgress } from "@mui/material";
import { Lager } from "@/Models/Lager";
import useAuthentication from "./../../../authentication/useAuthentication";

type WarenkorbLagerPickerProps = {
    selectLager: (lager: Lager) => void;
};
export default function WarenkorbLagerPicker(props: Readonly<WarenkorbLagerPickerProps>) {
    const { firmaIdAnforderer } = useAuthentication();
    const { cartLager, cartItemCount } = useContext(AppContext);
    const { selectLager } = props;
    const { data, error, isLoading } = useGetLagerOfFirma(firmaIdAnforderer);

    const [selectedLager, setSelectedLager] = useState<Lager | undefined>(cartLager);

    useEffect(() => {
        if (selectedLager) {
            selectLager(selectedLager);
        }
    }, [selectedLager]);

    return (
        <div style={{ marginBottom: "30px" }}>
            <h4>Wähle ein Lager für deinen Abruf aus</h4>
            {isLoading && <LinearProgress />}
            {error && (
                <Alert>
                    <AlertTitle>Fehler beim Laden der Lager</AlertTitle>
                    {error}
                </Alert>
            )}
            <div style={{ marginBottom: "30px" }}>Artikel in deinem Warenkorb werden immer auf das aktuell ausgewählte Lager bestellt.</div>
            {data.map((l, no) => {
                return (
                    <Button
                        sx={{ marginLeft: "20px" }}
                        onClick={async () => {
                            if (data.length > 1 && cartLager !== undefined && cartLager !== l && cartItemCount > 0) {
                                let confirmClearing = window.confirm(
                                    "Du hast bereits Artikel im Warenkorb für ein anderes Lager.\nDer Warenkorb wird geleert wenn du das Lager wechselst.\n\nBist du dir sicher?"
                                );

                                if (confirmClearing) {
                                    setSelectedLager(l);
                                }
                            } else {
                                setSelectedLager(l);
                            }
                        }}
                        variant={cartLager?.id === l.id ? "contained" : "outlined"}
                        key={no}
                    >
                        {l.name + " " + l.nummer}
                    </Button>
                );
            })}
            <hr style={{ marginTop: "30px" }}></hr>
        </div>
    );
}
