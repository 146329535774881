import SearchIcon from "@mui/icons-material/Search";
import { Button, InputAdornment, TextField, Toolbar } from "@mui/material";
import { useState } from "react";

type CustomGridToolbarSearchOnServerProps = {
    onSearch: (searchFor: string) => void;
};
export default function CustomGridToolbarSearchOnServer(props: Readonly<CustomGridToolbarSearchOnServerProps>) {
    const [searchPhrase, setSearchPhrase] = useState<string>("");
    const search = () => {
        props.onSearch(searchPhrase);
    };
    return (
        <Toolbar>
            <TextField
                variant="standard"
                placeholder="Suchen"
                size="small"
                onChange={(e) => setSearchPhrase(e.currentTarget.value)}
                value={searchPhrase}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Button variant="outlined" size="small" sx={{ marginLeft: 1 }} onClick={() => search()}>
                Suche
            </Button>
        </Toolbar>
    );
}
