import { useState } from "react";
import axiosClient from "../../ApiClient";

export default function usePostFirma<T>(route: string, firmaId: string) {
    const [data, setData] = useState<T | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const createObject = async (obj: T) => {
        setIsLoading(true);
        setError("");
        let uri = route.replace("{firmaId}", firmaId);
        console.log(uri);
        await axiosClient
            .post<T>(`${uri}`, obj)
            .then((response) => {
                setData(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return { data, isLoading, error, createObject };
}
